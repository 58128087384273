import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../medicaldashboard.component.css";
// import "font-awesome/css/font-awesome.min.css";
// import Surgery from "../../../assets/surgery.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import Calendar from "../../../assets/calendar.png";
// import paperclip from "../../../assets/paper-clip.png";
// import File from "../../../assets/attached-file.png";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import axios from "axios";
import "./cards.component.css";
import UpdateRecord from "../update-new-record/updaterecord.component.jsx";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GrIcons from "react-icons/gr";
import DialogTitle from "@mui/material/DialogTitle";
import { switchUrls } from "../../../api/index.js";
import axiosInstance from "../../../api/axiosinstance.js";

const baseURL = switchUrls("genie");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let token = usertoken;

function Surgerycard({
  tag_number,
  Surgery_ReportsNames,
  Surgery_ReportsLocations,
  Surgery_Reportlocation,
  Surgery_Reportname,
  surgery_id,
  Surgery_name,
  SurgeryDoctor_name,
  Surgery_date,
  SurgeryNotes,
  SurgeryTitle,
  SurgeryNext,
  SurgeryReminderNotes,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [docs, setDocs] = useState([]);
  const [show, setShow] = useState(false);
  const [showDocument, setShowDocument] = useState(true);
  let reports = [];

  function setreports() {
    for (let i = 0; i < Surgery_ReportsLocations.length; i++) {
      let obj = {
        name: Surgery_ReportsNames[i],
        location: Surgery_ReportsLocations[i],
      };

      reports.push(obj);
    }

    setDocs(reports);
  }

  useEffect(() => {
    setreports();
  }, []);

  const handleCloseUpdate = () => {
    setShowUpdate(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const convertISOStringToMonthDay = (date) => {
    const tempDate = new Date(date).toString().split(" ");
    const formattedDate = `${tempDate[2]} ${tempDate[1]}, ${tempDate[3]}`;

    return formattedDate;
  };

  const handleClose1 = () => {
    setShow(false);
  };

  function deletedetails() {
    const state = {
      tag_number: tag_number,
      surgery_id: surgery_id,
    };

    axiosInstance
      .post("/api/pet/deleteMedicalDetails", state,)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function capitalize(str) {
    if (str) {
      const word = str.charAt(0).toUpperCase() + str.slice(1);

      return word;
    }
  }

  return (
    <>
      <div className="cards-component-wrapper">
        <div className="cards-component-container">
          <div className="left-side-content cards-component-container-head">
            <img
              src={
                "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
              }
              alt="surgery"
            />
            <h5 className="record-name">{capitalize(Surgery_name)}</h5>
            <div className="tag-number">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                style={{ width: "20px", height: "20px" }}
              />
              <span className="mx-1">{tag_number} </span>
            </div>
            <div className={`cards-component-main-wrapper mobile-view`}>
              <div className="cards-component-main-content">
                <label>Performed on</label>

                <h5>{convertISOStringToMonthDay(Surgery_date)}</h5>
              </div>

              {SurgeryDoctor_name ? (
                <div className="cards-component-main-content">
                  <label>Performed by</label>

                  <h5>{SurgeryDoctor_name}</h5>
                </div>
              ) : (
                ""
              )}
              {SurgeryNotes ? (
                <div className="cards-component-main-content reminder-notes">
                  <label>Notes</label>

                  <h6>{SurgeryNotes}</h6>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* middle content */}
          <div className="middle-content">
            <div className="cards-component-main-wrapper-flex">
              <div className={`cards-component-main-wrapper laptop-view`}>
                <div className="cards-component-main-content">
                  <label>Performed on</label>

                  <h5>{convertISOStringToMonthDay(Surgery_date)}</h5>
                </div>

                {SurgeryDoctor_name ? (
                  <div className="cards-component-main-content">
                    <label>Performed by</label>

                    <h5>{SurgeryDoctor_name}</h5>
                  </div>
                ) : (
                  ""
                )}
                {SurgeryNotes ? (
                  <div className="cards-component-main-content reminder-notes">
                    <label>Notes</label>

                    <h6>{SurgeryNotes}</h6>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="cards-component-main-wrapper">
                <div className="cards-component-main-content">
                  <label>
                    <AiIcons.AiFillCalendar />
                    Reminder
                  </label>

                  {SurgeryNext ? (
                    <h5>{convertISOStringToMonthDay(SurgeryNext)}</h5>
                  ) : (
                    <h5 className="text-muted">No reminder</h5>
                  )}
                </div>

                <div className="cards-component-main-content">
                  <label>Title</label>

                  <h5>
                    {SurgeryTitle ? (
                      <span>{SurgeryTitle}</span>
                    ) : (
                      <span className="text-muted">No Title</span>
                    )}
                  </h5>
                </div>
                <div className="cards-component-main-content reminder-notes">
                  <label>Notes</label>

                  <h6>
                    {SurgeryReminderNotes ? (
                      <span>{SurgeryReminderNotes}</span>
                    ) : (
                      <span className="text-muted"> No Notes </span>
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="cards-component-kebab-menu">
              <Tooltip title="Edit Record">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/edits.png"
                  className="edit-btn"
                  onClick={() => {
                    setShowUpdate(true);
                    handleClose();
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete Record">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/delete.png"
                  className="delete-btn"
                  onClick={() => {
                    setShow(true);
                    handleClose();
                  }}
                />
              </Tooltip>
            </div>
            <div className="right-side-content">
              {docs ? (
                <div className="cards-component-main-document-content">
                  <Tooltip title="Medical Documents">
                    <img
                      src="https://storage.googleapis.com/pettag/qr/assets/medical-file.png"
                      alt="documentIcon"
                      className="document-icon"
                      onClick={() => {
                        setShowDocument(!showDocument);
                      }}
                    />
                  </Tooltip>
                  <div className={`${showDocument ? "hide" : ""}`}>
                    <span className="document-info">
                      {Surgery_Reportlocation ? (
                        <Tooltip title={Surgery_Reportname}>
                          <a href={Surgery_Reportlocation}>
                            <img
                              src="https://storage.googleapis.com/pettag/qr/assets/health-check.png"
                              className="downloaded-img-icon"
                            />
                          </a>
                        </Tooltip>
                      ) : null}

                      {docs.length
                        ? docs.map((rep) => (
                            <Tooltip title={rep.name || rep.location}>
                              <a href={rep.location}>
                                <img
                                  src="https://storage.googleapis.com/pettag/qr/assets/health-check.png"
                                  className="downloaded-img-icon"
                                />
                              </a>
                            </Tooltip>
                          ))
                        : null}

                      {Surgery_Reportlocation || docs.length ? null : (
                        <p>No documents </p>
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={show}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          {"Are you sure you want to delete this Surgery card?"}
        </DialogTitle>

        <div className="cards-component-delete-dialog">
          <button onClick={deletedetails}>Confirm</button>

          <button onClick={() => setShow(false)}>Cancel</button>
        </div>
      </Dialog>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15, maxWidth: "835px" },
        }}
        open={showUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <UpdateRecord
          surgery_id={surgery_id}
          defaultRecord={"SURGERY"}
          medicalTabActive={1}
          closeDialog={handleCloseUpdate}
        />
      </Dialog>
    </>
  );
}

export default Surgerycard;
