import React, { useEffect } from "react";
import { switchUrls } from "../../api/index.js";

function Home() {
  const dashboardUrl = switchUrls("gateway"); // Dynamically fetch the correct URL

  useEffect(() => {
    if (dashboardUrl) {
      // Redirect to the external URL
      window.location.href = dashboardUrl;
    }
  }, [dashboardUrl]);

  return null; // Prevent rendering anything
}

export default Home;