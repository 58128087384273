import axios from "axios";
import { switchUrls } from ".";

const axiosBaseurl = switchUrls("genie");

//function to get the token from cookie
const getCookie = (cookieName) => {
  const cookies = document.cookie.split("; ");
  const cookie = cookies.find((c) => c.startsWith(`${cookieName}=`));
  return cookie ? cookie.split("=")[1] : null;
};

const delete_cookie = (name) => {
    const domain = window.location.hostname.includes("localhost")
      ? "localhost"
      : window.location.hostname.includes("tag8.co.in")
      ? ".tag8.co.in"
      : window.location.hostname.includes("tag8.in")
      ? ".tag8.in"
      : "";
  
    if (domain) {
      document.cookie = `${name}=; Path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    } else {
      document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  };

const axiosInstance = axios.create({
  baseURL: axiosBaseurl,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      const token = getCookie("token");
      console.log(token)
      if (token != null) {
        config.headers.Authorization = token;
      }
    } catch (err) {
      // handel error
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
        console.log(error?.response?.data)
      if (error?.response?.data?.authError) {
       
        delete_cookie("token")
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

export default axiosInstance;
