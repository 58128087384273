import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import "./updaterecord.component.css";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Breadcrumbs,
  Button,
  Link,
  TextareaAutosize,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import MaterialFileUpload from "react-material-file-upload";
// import { ScrollableTabsButtonAuto } from "@mui/lab";
import {
  PhoneAndroid as PhoneIcon,
  Favorite,
  Person as PersonPinIcon,
} from "@material-ui/icons";
import { AiOutlineUpload, AiFillFilePdf, AiFillFile } from "react-icons/ai";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../../api/index.js";
import axiosInstance from "../../../api/axiosinstance.js";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

const baseURL = switchUrls("genie");

let medication_start_date = 1;
let usertoken = readCookie("token");
let reports = [];

const sectionMap = {
  0: "Vaccination",
  1: "Surgery",
  2: "Allergy",
  3: "Medication",
  4: "Medicalcondition",
  5: "Measurement",
};
export default class updateRecord extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", this.props);
    this.state = {
      imageUrl: null,
      selectedImage: null,
      vaccination_reminder: false,
      surgery_reminder: false,
      allergy_reminder: false,
      medication_reminder: false,
      medicalcondition_reminder: false,
      measurement_reminder: false,
      tag_number: localStorage.getItem("tag_number"),
      // selectedSection: "vaccination",
      Type: "Vaccination",
      value: this.props.medicalTabActive,
      vaccination_id: this.props.vaccination_id,
      Vaccination_name: "",
      VaccinationDoctor_name: "",
      VaccinationAdministration_date: "",
      VaccinationNotes: "",
      VaccinationTitle: "",
      VaccinationEverynumber: "",
      VaccinationEvery: "",
      VaccinationNext: "",
      VaccinationRemind_me: "",
      VaccinationReminderNotes: "",
      VaccinationDone: false,
      Vaccination_Reportnames: [],
      Vaccination_Reportlocations: [],

      allergy_id: this.props.allergy_id,
      Allergy_name: "",
      AllergyDoctor_name: "",
      AllergyDiagnosis_date: "",
      AllergySeverity: "",
      AllergyNotes: "",
      AllergyTitle: "",
      AllergyEverynumber: "",
      AllergyEvery: "",
      AllergyNext: "",
      AllergyRemind_me: "",
      AllergyReminderNotes: "",
      AllergyDone: false,
      Allergy_Reportnames: [],
      Allergy_Reportlocations: [],

      surgery_id: this.props.surgery_id,
      Surgery_name: "",
      SurgeryDoctor_name: "",
      Surgery_date: "",
      SurgerySeverity: "",
      SurgeryNotes: "",
      SurgeryTitle: "",
      SurgeryEverynumber: "",
      SurgeryEvery: "",
      SurgeryNext: "",
      SurgeryRemind_me: "",
      SurgeryReminderNotes: "",
      SurgeryDone: false,
      Surgery_Reportname: "",
      Surgery_Reportnames: [],
      Surgery_Reportlocation: "",
      Surgery_Reportlocations: [],

      medication_id: this.props.medication_id,
      Medication_name: "",
      MedicationDoctor_name: "",
      Medication_startdate: "",
      Medication_enddate: "",
      MedicationDosage: "",
      MedicationNotes: "",
      MedicationTitle: "",
      MedicationEverynumber: "",
      MedicationEvery: "",
      MedicationNext: "",
      MedicationRemind_me: "",
      MedicationReminderNotes: "",
      MedicationDone: false,
      Medication_Reportnames: [],
      Medication_Reportlocations: [],

      medicalcondition_id: this.props.medicalcondition_id,
      Medicalcondition_type: "",
      MedicalconditionDoctor_name: "",
      MedicalconditionDiagnosed_date: "",
      Medicalcondition_enddate: "",
      MedicalconditionSeverity: "",
      MedicalconditionNotes: "",
      MedicalconditionTitle: "",
      MedicalconditionEverynumber: "",
      MedicalconditionEvery: "",
      MedicalconditionNext: "",
      MedicalconditionRemind_me: "",
      MedicalconditionReminderNotes: "",
      MedicalconditionDone: false,
      Medicalcondition_Reportnames: [],
      Medicalcondition_Reportlocations: [],

      measurement_id: this.props.measurement_id,
      Height: "",
      Weight: "",
      Measurement_date: "",
      Measuredby: "",
      MeasurementNotes: "",
      MeasurementTitle: "",
      MeasurementEverynumber: "",
      MeasurementEvery: "",
      MeasurementNext: "",
      MeasurementRemind_me: "",
      MeasurementReminderNotes: "",
      MeasurementDone: false,
      Measurement_Reportnames: [],
      Measurement_Reportlocations: [],

      Vaccination_Reportname: "",
      Vaccination_Reportlocation: "",
      Allergy_Reportname: "",
      Allergy_Reportlocation: "",
      Surgery_Reportname: "",
      Surgery_Reportlocation: "",
      Medication_Reportname: "",
      Medication_Reportlocation: "",
      Medicalcondition_Reportname: "",
      Medicalcondition_Reportlocation: "",
      Measurement_Reportname: "",
      Measurement_Reportlocation: "",
      message: "",
      docs: [],
      files: [],
      file: "",
      setfile: false,
      img_remove_box : false,
      main_index : "",
   
      delete_values : {
        tag_number : this.props?.match?.params?.tagnumber || localStorage?.getItem("tag_number") ,
        index : "",
        reportStatus : "",
        cardValue : "",
        main_index : ""
      }
    };
    this.removeConfirm = this.removeConfirm.bind(this);
    this.fileConfirmation = this.fileConfirmation.bind(this)
    this.confirmboxHandel = this.confirmboxHandel.bind(this)
   
   
  }



  confirmboxHandel = (index, reportStatus, cardValue,file,id)=>{
    if(file){

      this.setState({img_remove_box:true})
      this.setState({
        
      delete_values : {
        tag_number : this.props?.match?.params?.tagnumber || localStorage?.getItem("tag_number") ,
        index,
        reportStatus,
        cardValue,
        file,
        main_index : id
      }
      })

    }
    else{
    
      const updatedFiles = [...this.state.files];
      updatedFiles.splice(index, 1);
      this.setState({ files: updatedFiles });
      this.setState({message:""})
    }

   
  }

  getSectionFromIndex = (index) => {
    return sectionMap[index];
  };

  componentDidMount() {
    console.log("default Record: ", this.props.defaultRecord);
    axiosInstance
      .get("/api/pet/getMedicalDetails/" + this.state.tag_number)
      .then((response) => {
        console.log("Medical Response: ", response.data[0]);
        const data = response.data[0];

        const formateDate = (dt) => {
          if (dt !== null) {
            let newDt = new Date(dt);
            const year = newDt.getFullYear();
            let month = newDt.getMonth() + 1;
            let day = newDt.getDate();
            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            return year + "-" + month + "-" + day;
          } else {
            return null;
          }
        };

        data.Vaccination.forEach((elm,index) => {
          // console.log(
          //   `props id: ${this.state.vaccination_id}-- ${
          //     this.state.vaccination_id === elm.vaccination_id
          //   } -- ${elm.vaccination_id} : database id `
          // );
          if (this.state.vaccination_id === elm.vaccination_id) {
            console.log("Vaccination Items Matched");
            this.setState({
              main_index : index,
              Vaccination_name: elm.Vaccination_name,
              VaccinationDoctor_name: elm.VaccinationDoctor_name,
              VaccinationAdministration_date: formateDate(
                elm.VaccinationAdministration_date
              ),
              VaccinationNotes: elm.VaccinationNotes,
              VaccinationDone: elm.VaccinationDone,
              VaccinationTitle: elm.VaccinationTitle,
              VaccinationEverynumber: elm.VaccinationEverynumber,
              VaccinationEvery: elm.VaccinationEvery,
              VaccinationNext: formateDate(elm.VaccinationNext),
              VaccinationRemind_me: elm.VaccinationRemind_me,
              VaccinationReminderNotes: elm.VaccinationReminderNotes,
              Vaccination_Reportname: elm.Vaccination_Reportname,
              Vaccination_Reportlocation: elm.Vaccination_Reportlocation,
              Vaccination_Reportnames: elm.Vaccination_ReportsNames,
              Vaccination_Reportlocations: elm.Vaccination_ReportsLocations,
            });
          }
          if (this.props.defaultRecord !== "VACCINATION") {
            console.log("Default Vaccination Record ");
            this.setState({
              main_index : index,
              Vaccination_name: elm.Vaccination_name,
              VaccinationDoctor_name: elm.VaccinationDoctor_name,
              VaccinationAdministration_date: formateDate(
                elm.VaccinationAdministration_date
              ),
              VaccinationNotes: elm.VaccinationNotes,
              VaccinationDone: elm.VaccinationDone,
              VaccinationTitle: elm.VaccinationTitle,
              VaccinationEverynumber: elm.VaccinationEverynumber,
              VaccinationEvery: elm.VaccinationEvery,
              VaccinationNext: formateDate(elm.VaccinationNext),
              VaccinationRemind_me: elm.VaccinationRemind_me,
              VaccinationReminderNotes: elm.VaccinationReminderNotes,
              Vaccination_Reportname: elm.Vaccination_Reportname,
              Vaccination_Reportlocation: elm.Vaccination_Reportlocation,
              Vaccination_Reportnames: elm.Vaccination_ReportsNames,
              Vaccination_Reportlocations: elm.Vaccination_ReportsLocations,
              vaccination_id: elm.vaccination_id,
            });
          }
        });

        data.Surgery.forEach((elm,index) => {
          // console.log(
          //   `props id: ${this.state.surgery_id}-- ${
          //     this.state.surgery_id === elm.surgery_id
          //   } -- ${elm.surgery_id} : database id `
          // );

          if (this.state.surgery_id === elm.surgery_id) {
            console.log("Surgery Items Matched");
            this.setState({
              main_index : index,
              Surgery_name: elm.Surgery_name,
              SurgeryDoctor_name: elm.SurgeryDoctor_name,
              Surgery_date: formateDate(elm.Surgery_date),
              SurgerySeverity: elm.SurgerySeverity,
              SurgeryNotes: elm.SurgeryNotes,
              SurgeryDone: elm.SurgeryDone,
              SurgeryTitle: elm.SurgeryTitle,
              SurgeryNext: formateDate(elm.SurgeryNext),
              SurgeryRemind_me: elm.SurgeryRemind_me,
              SurgeryReminderNotes: elm.SurgeryReminderNotes,
              Surgery_Reportname: elm.Surgery_Reportname,
              Surgery_Reportlocation: elm.Surgery_Reportlocation,
              Surgery_Reportnames: elm.Surgery_ReportsNames,
              Surgery_Reportlocations: elm.Surgery_ReportsLocations,
            });
          }
          if (this.props.defaultRecord !== "SURGERY") {
            console.log("Default Surgery Record ");
            this.setState({
              main_index : index,
              Surgery_name: elm.Surgery_name,
              SurgeryDoctor_name: elm.SurgeryDoctor_name,
              Surgery_date: formateDate(elm.Surgery_date),
              SurgerySeverity: elm.SurgerySeverity,
              SurgeryNotes: elm.SurgeryNotes,
              SurgeryDone: elm.SurgeryDone,
              SurgeryTitle: elm.SurgeryTitle,
              SurgeryNext: formateDate(elm.SurgeryNext),
              SurgeryRemind_me: elm.SurgeryRemind_me,
              SurgeryReminderNotes: elm.SurgeryReminderNotes,
              Surgery_Reportname: elm.Surgery_Reportname,
              Surgery_Reportlocation: elm.Surgery_Reportlocation,
              Surgery_Reportnames: elm.Surgery_ReportsNames,
              Surgery_Reportlocations: elm.Surgery_ReportsLocations,
              surgery_id: elm.surgery_id,
            });
          }
        });

        data.Allergy.forEach((elm,index) => {
          // console.log(
          //   `props id: ${this.state.allergy_id}-- ${
          //     this.state.allergy_id === elm.allergy_id
          //   } -- ${elm.allergy_id} : database id `
          // );

          if (this.state.allergy_id === elm.allergy_id) {
            console.log("Allergy Items Matched");
            this.setState({
              main_index : index,
              Allergy_name: elm.Allergy_name,
              AllergyDoctor_name: elm.AllergyDoctor_name,
              AllergyDiagnosis_date: formateDate(elm.AllergyDiagnosis_date),
              AllergySeverity: elm.AllergySeverity,
              AllergyNotes: elm.AllergyNotes,
              AllergyTitle: elm.AllergyTitle,
              AllergyNext: formateDate(elm.AllergyNext),
              AllergyRemind_me: elm.AllergyRemind_me,
              AllergyReminderNotes: elm.AllergyReminderNotes,
              AllergyDone: elm.AllergyDone,
              Allergy_Reportname: elm.Allergy_Reportname,
              Allergy_Reportlocation: elm.Allergy_Reportlocation,
              Allergy_Reportnames: elm.Allergy_ReportsNames,
              Allergy_Reportlocations: elm.Allergy_ReportsLocations,
            });
          }

          if (this.props.defaultRecord !== "ALLERGY") {
            console.log("Default Allergy Record ");
            this.setState({
              main_index : index,
              Allergy_name: elm.Allergy_name,
              AllergyDoctor_name: elm.AllergyDoctor_name,
              AllergyDiagnosis_date: formateDate(elm.AllergyDiagnosis_date),
              AllergySeverity: elm.AllergySeverity,
              AllergyNotes: elm.AllergyNotes,
              AllergyTitle: elm.AllergyTitle,
              AllergyNext: formateDate(elm.AllergyNext),
              AllergyRemind_me: elm.AllergyRemind_me,
              AllergyReminderNotes: elm.AllergyReminderNotes,
              AllergyDone: elm.AllergyDone,
              Allergy_Reportname: elm.Allergy_Reportname,
              Allergy_Reportlocation: elm.Allergy_Reportlocation,
              Allergy_Reportnames: elm.Allergy_ReportsNames,
              Allergy_Reportlocations: elm.Allergy_ReportsLocations,
              allergy_id: elm.allergy_id,
            });
          }
        });

        data.Medication.forEach((elm,index) => {
          // console.log(
          //   `props id: ${this.state.medication_id}-- ${
          //     this.state.medication_id === elm.medication_id
          //   } -- ${elm.medication_id} : database id `
          // );

          if (this.state.medication_id === elm.medication_id) {
            console.log("Medication Items Matched");
            this.setState({
              main_index : index,
              Medication_name: elm.Medication_name,
              MedicationDoctor_name: elm.MedicationDoctor_name,
              Medication_startdate: formateDate(elm.Medication_startdate),
              Medication_enddate: formateDate(elm.Medication_enddate),
              MedicationDosage: elm.MedicationDosage,
              MedicationNotes: elm.MedicationNotes,
              MedicationTitle: elm.MedicationTitle,
              MedicationNext: formateDate(elm.MedicationNext),
              MedicationRemind_me: elm.MedicationRemind_me,
              MedicationReminderNotes: elm.MedicationReminderNotes,
              MedicationDone: elm.MedicationDone,
              Medication_Reportname: elm.Medication_Reportname,
              Medication_Reportlocation: elm.Medication_Reportlocation,
              Medication_Reportnames: elm.Medication_ReportsNames,
              Medication_Reportlocations: elm.Medication_ReportsLocations,
            });
          }
          if (this.props.defaultRecord !== "MEDICATION") {
            console.log("Default Medication Record ");
            this.setState({
              main_index : index,
              Medication_name: elm.Medication_name,
              MedicationDoctor_name: elm.MedicationDoctor_name,
              Medication_startdate: formateDate(elm.Medication_startdate),
              Medication_enddate: formateDate(elm.Medication_enddate),
              MedicationDosage: elm.MedicationDosage,
              MedicationNotes: elm.MedicationNotes,
              MedicationTitle: elm.MedicationTitle,
              MedicationNext: formateDate(elm.MedicationNext),
              MedicationRemind_me: elm.MedicationRemind_me,
              MedicationReminderNotes: elm.MedicationReminderNotes,
              MedicationDone: elm.MedicationDone,
              Medication_Reportname: elm.Medication_Reportname,
              Medication_Reportlocation: elm.Medication_Reportlocation,
              Medication_Reportnames: elm.Medication_ReportsNames,
              Medication_Reportlocations: elm.Medication_ReportsLocations,
              medication_id: elm.medication_id,
            });
          }
        });

        data.Medicalcondition.forEach((elm,index) => {
          // console.log(
          //   `props id: ${this.state.medicalcondition_id}-- ${
          //     this.state.medicalcondition_id === elm.medicalcondition_id
          //   } -- ${elm.medicalcondition_id} : database id `
          // );

          if (this.state.medicalcondition_id === elm.medicalcondition_id) {
            console.log("Medical Condition Items Matched");
            console.log(elm);
            this.setState({
              main_index : index,
              Medicalcondition_type: elm.Medicalcondition_type,
              MedicalconditionDoctor_name: elm.MedicalconditionDoctor_name,
              MedicalconditionDiagnosed_date: formateDate(
                elm.MedicalconditionDiagnosed_date
              ),
              MedicalconditionSeverity: elm.MedicalconditionSeverity,
              MedicalconditionNotes: elm.MedicalconditionNotes,
              MedicalconditionTitle: elm.MedicalconditionTitle,
              MedicalconditionNext: formateDate(elm.MedicalconditionNext),
              MedicalconditionRemind_me: elm.MedicalconditionRemind_me,
              MedicalconditionReminderNotes: elm.MedicalconditionReminderNotes,
              MedicalconditionDone: elm.MedicalconditionDone,
              Medicalcondition_Reportname: elm.Medicalcondition_Reportname,
              Medicalcondition_Reportlocation:
                elm.Medicalcondition_Reportlocation,
              Medicalcondition_Reportnames: elm.Medicalcondition_ReportsNames,
              Medicalcondition_Reportlocations:
                elm.Medicalcondition_ReportsLocations,
            });
          }
          if (this.props.defaultRecord !== "MEDICALCONDITION") {
            console.log("Default Medical Condition Record ");
            // console.log(elm);
            this.setState({
              main_index : index,
              Medicalcondition_type: elm.Medicalcondition_type,
              MedicalconditionDoctor_name: elm.MedicalconditionDoctor_name,
              MedicalconditionDiagnosed_date: formateDate(
                elm.MedicalconditionDiagnosed_date
              ),
              MedicalconditionSeverity: elm.MedicalconditionSeverity,
              MedicalconditionNotes: elm.MedicalconditionNotes,
              MedicalconditionTitle: elm.MedicalconditionTitle,
              MedicalconditionNext: formateDate(elm.MedicalconditionNext),
              MedicalconditionRemind_me: elm.MedicalconditionRemind_me,
              MedicalconditionReminderNotes: elm.MedicalconditionReminderNotes,
              MedicalconditionDone: elm.MedicalconditionDone,
              Medicalcondition_Reportname: elm.Medicalcondition_Reportname,
              Medicalcondition_Reportlocation:
                elm.Medicalcondition_Reportlocation,
              Medicalcondition_Reportnames: elm.Medicalcondition_ReportsNames,
              Medicalcondition_Reportlocations:
                elm.Medicalcondition_ReportsLocations,
              medicalcondition_id: elm.medicalcondition_id,
            });
          }
        });

        data.Measurement.forEach((elm,index) => {
          // console.log(
          //   `props id: ${this.state.measurement_id}-- ${
          //     this.state.measurement_id === elm.measurement_id
          //   } -- ${elm.measurement_id} : database id `
          // );
          if (this.state.measurement_id === elm.measurement_id) {
            console.log("Mesurment Items Matched");
            console.log(elm);
            this.setState({
              main_index : index,
              Height: elm.Height,
              Weight: elm.Weight,
              Measurement_date: formateDate(elm.Measurement_date),
              Measuredby: elm.Measuredby,
              MeasurementNotes: elm.MeasurementNotes,
              MeasurementTitle: elm.MeasurementTitle,
              MeasurementNext: formateDate(elm.MeasurementNext),
              MeasurementRemind_me: elm.MeasurementRemind_me,
              MeasurementReminderNotes: elm.MeasurementReminderNotes,
              MeasurementDone: elm.MeasurementDone,
              Measurement_Reportname: elm.Measurement_Reportname,
              Measurement_Reportlocation: elm.Measurement_Reportlocation,
              Measurement_Reportnames: elm.Measurement_ReportsNames,
              Measurement_Reportlocations: elm.Measurement_ReportsLocations,
            });
          }
          if (this.props.defaultRecord !== "MEASUREMENT") {
            console.log("Default Mesurment Record ");
            this.setState({
              main_index : index,
              Height: elm.Height,
              Weight: elm.Weight,
              Measurement_date: formateDate(elm.Measurement_date),
              Measuredby: elm.Measuredby,
              MeasurementNotes: elm.MeasurementNotes,
              MeasurementTitle: elm.MeasurementTitle,
              MeasurementNext: formateDate(elm.MeasurementNext),
              MeasurementRemind_me: elm.MeasurementRemind_me,
              MeasurementReminderNotes: elm.MeasurementReminderNotes,
              MeasurementDone: elm.MeasurementDone,
              Measurement_Reportname: elm.Measurement_Reportname,
              Measurement_Reportlocation: elm.Measurement_Reportlocation,
              Measurement_Reportnames: elm.Measurement_ReportsNames,
              Measurement_Reportlocations: elm.Measurement_ReportsLocations,
              measurement_id: elm.measurement_id,
            });
          }
        });
      })
      .catch((error) => {
        console.error(error.message);
      });

    const defaultSection = this.getSectionFromIndex(this.state.value);
    this.setState({ Type: defaultSection });
  }
  changeHandler = (e) => {
    console.log([e.target.name], e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  change_new_handler = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    medication_start_date = e.target.value;
  };

  handleChange = (event,repotfiles) => {
    const file = event?.target?.files[0];
    if(file){

      const fileName = file.name;
    const fileSize = file.size;

    const fileExtension = fileName.split(".")[1];
    const size = fileSize / (1024 * 1024);

    const regex = /\./gm;

    const isValidFileExtension = [
      "pdf",
      "docx",
      "docs",
      "png",
      "jpg",
      "jpeg",
      "csv",
      "xlsx",
    ].includes(fileExtension);
    const isValidSize = size < 5 ? true : false;
    const isValidFileName = fileName.match(regex).length === 1 ? true : false;

    const totalFiles = repotfiles?.length + this.state?.files?.length
    

    const islimitReached = totalFiles < 2


    if (isValidFileExtension && isValidSize && isValidFileName && islimitReached) {
      this.setState({ message: "" });
      this.setState((prevState) => ({
        message: "",
        files: [...prevState.files, file],
      }));

      

      const currentSection = sectionMap[this.state.value];
      this.setState({ Type: currentSection });
    } else if (!isValidFileName) {
      this.setState({
        message:
          "Oops!! File name is not appropriate. Kindly rename the file and try again.",
      });
    } else if (!isValidSize) {
      this.setState({ message: "File size should be less than 5 MB" });
    } else if (!isValidFileExtension) {
      this.setState({
        message: `File with extension .${fileExtension} are not allowed!`,
      });
    }
    else if (!islimitReached){
      this.setState({
        message: `Only 2 files can be uploaded at a time.`,
      });
    }

    }
    
  };

  removeConfirm = ()=>{
    
    this.setState({img_remove_box : true})
  }

  fileConfirmation = ()=>{
    console.log('reached file confirm')
    this.handleRemove(this.state.delete_values.index, this.state.delete_values.reportStatus, this.state.delete_values.cardValue)
  }
  
  handleRemove = (index, reportStatus, cardValue) => {
   
    console.log("index: ", index);
    if (reportStatus === "DELETE" && cardValue === "VACCINATION") {
      const updatedReportnames1 = [...this.state.Vaccination_Reportnames];
      updatedReportnames1.splice(index, 1);
      const updatedReportlocations1 = [
        ...this.state.Vaccination_Reportlocations,
      ];
      updatedReportlocations1.splice(index, 1);
      this.setState({
        Vaccination_Reportnames: updatedReportnames1,
        Vaccination_Reportlocations: updatedReportlocations1,
      });
    } else if (reportStatus === "DELETE" && cardValue === "SURGERY") {
      const updatedReportnames2 = [...this.state.Surgery_Reportnames];
      updatedReportnames2.splice(index, 1);
      const updatedReportlocations2 = [...this.state.Surgery_Reportlocations];
      updatedReportlocations2.splice(index, 1);
      this.setState({
        Surgery_Reportnames: updatedReportnames2,
        Surgery_Reportlocations: updatedReportlocations2,
      });
    } else if (reportStatus === "DELETE" && cardValue === "ALLERGY") {
      console.log("ALLERGY");
      const updatedReportnames3 = [...this.state.Allergy_Reportnames];
      updatedReportnames3.splice(index, 1);
      const updatedReportlocations3 = [...this.state.Allergy_Reportlocations];
      updatedReportlocations3.splice(index, 1);
      this.setState({
        Allergy_Reportnames: updatedReportnames3,
        Allergy_Reportlocations: updatedReportlocations3,
      });
    } else if (reportStatus === "DELETE" && cardValue === "MEDICATION") {
      const updatedReportnames4 = [...this.state.Medication_Reportnames];
      updatedReportnames4.splice(index, 1);
      const updatedReportlocations4 = [
        ...this.state.Medication_Reportlocations,
      ];
      updatedReportlocations4.splice(index, 1);
      this.setState({
        Medication_Reportnames: updatedReportnames4,
        Medication_Reportlocations: updatedReportlocations4,
      });
    } else if (reportStatus === "DELETE" && cardValue === "MEDICALCONDITION") {
      const updatedReportnames5 = [...this.state.Medicalcondition_Reportnames];
      updatedReportnames5.splice(index, 1);
      const updatedReportlocations5 = [
        ...this.state.Medicalcondition_Reportlocations,
      ];
      updatedReportlocations5.splice(index, 1);
      this.setState({
        Medicalcondition_Reportnames: updatedReportnames5,
        Medicalcondition_Reportlocations: updatedReportlocations5,
      });
    } else if (reportStatus === "DELETE" && cardValue === "MEASUREMENT") {
      const updatedReportnames6 = [...this.state.Measurement_Reportnames];
      updatedReportnames6.splice(index, 1);
      const updatedReportlocations6 = [
        ...this.state.Measurement_Reportlocations,
      ];
      updatedReportlocations6.splice(index, 1);
      this.setState({
        Measurement_Reportnames: updatedReportnames6,
        Measurement_Reportlocations: updatedReportlocations6,
      });
    }

    if (reportStatus === "CANCEL") {
      const updatedFiles = [...this.state.files];
      updatedFiles.splice(index, 1);
      this.setState({ files: updatedFiles });
    }

    if(reportStatus === "DELETE" && cardValue){
      axiosInstance.post("/api/pet/reportdelete",this.state?.delete_values
        
        
      ).then((data)=>{
        this.setState({
          message : "",
          img_remove_box : false,
          main_index : "",
          delete_values : {
            tag_number : this.props?.match?.params?.tagnumber || localStorage?.getItem("tag_number") ,
            index : "",
            reportStatus : "",
            cardValue : "",
            file : "",
            main_index : ""
          }
          })
      })
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    axiosInstance
      .post("/api/pet/updateMedicalDetails", this.state,)
      .then((res) => {
        console.log("Submit res: ", res);

        const fd = new FormData();
        for (var i = 0; i < this.state.files.length; i++) {
          fd.append("image", this.state.files[i]);
        }

        fd.append("tag_number", this.state.tag_number);
        fd.append("Type", this.state.Type);

        if (this.state.Type === "Vaccination") {
          fd.append("vaccination_id", this.state.vaccination_id);
        } else if (this.state.Type === "Allergy") {
          fd.append("allergy_id", this.state.allergy_id);
        } else if (this.state.Type === "Surgery") {
          fd.append("surgery_id", this.state.surgery_id);
        } else if (this.state.Type === "Medication") {
          fd.append("medication_id", this.state.medication_id);
        } else if (this.state.Type === "Medicalcondition") {
          fd.append("medicalcondition_id", this.state.medicalcondition_id);
        } else if (this.state.Type === "Measurement") {
          fd.append("measurement_id", this.state.measurement_id);
        }

        if (this.state.files.length !== 0) {
          axiosInstance
            .post("/api/pet/reports-upload", fd, {
              Accept: "application/json",
            })
            .then((response) => {
              console.info(response);
              window.location.reload();
            })
            .catch((error) => {
              console.error(error.message);
            });
        } else {
          // console.log("report not uploaded");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error.message);

        this.props.closeDialog();
      });
  };

  handleSectionChange = (section) => {
    // Implement your logic for section change here
    // console.log(`Changing to section: ${section}`);
    this.setState({ value: section });
  };

  render() {
    const {
      Type,
      vaccination_reminder,
      Vaccination_name,
      VaccinationDoctor_name,
      VaccinationAdministration_date,
      VaccinationNotes,
      VaccinationTitle,
      VaccinationNext,
      VaccinationRemind_me,
      VaccinationReminderNotes,
      VaccinationDone,
      Vaccination_Reportname,
      Vaccination_Reportlocation,

      medicalcondition_reminder,
      Medicalcondition_type,
      MedicalconditionDoctor_name,
      MedicalconditionDiagnosed_date,
      MedicalconditionSeverity,
      MedicalconditionNotes,
      MedicalconditionTitle,
      MedicalconditionNext,
      MedicalconditionRemind_me,
      MedicalconditionReminderNotes,
      MedicalconditionDone,
      Medicalcondition_Reportname,
      Medicalcondition_Reportlocation,

      surgery_reminder,
      SurgeryTitle,
      Surgery_name,
      SurgeryDoctor_name,
      Surgery_date,
      SurgerySeverity,
      SurgeryNext,
      SurgeryRemind_me,
      SurgeryReminderNotes,
      SurgeryNotes,
      Surgery_Reportlocation,
      Surgery_Reportname,

      Allergy_name,
      AllergyDoctor_name,
      AllergyDiagnosis_date,
      AllergySeverity,
      AllergyNotes,
      allergy_reminder,
      AllergyTitle,
      AllergyNext,
      AllergyRemind_me,
      AllergyReminderNotes,
      medication_start_date,

      medication_reminder,
      Medication_name,
      MedicationDoctor_name,
      Medication_startdate,
      Medication_enddate,
      MedicationDosage,
      MedicationNotes,
      MedicationTitle,
      MedicationNext,
      MedicationRemind_me,
      MedicationReminderNotes,
      MedicationDone,
      Medication_Reportname,
      Medication_Reportlocation,

      measurement_reminder,
      Height,
      Weight,
      Measurement_date,
      Measuredby,
      MeasurementNotes,
      MeasurementTitle,
      MeasurementNext,
      MeasurementRemind_me,
      MeasurementReminderNotes,
      MeasurementDone,
      Measurement_Reportname,
      Measurement_Reportlocation,
      docs,
      value,
      img_remove_box,
      delete_values,
      vaccination_id,
      allergy_id,
      surgery_id,
      measurement_id,
      medication_id,
      medicalcondition_id
    } = this.state;
    console.log("Initial State: ", this.state);

    return (
      <>
        <form onSubmit={this.submitHandler} className="addNewRecord-form">
          <Tabs
            value={value}
            onChange={this.handleSectionChange}
            aria-label="icon label tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PhoneIcon />}
                label="Vaccination"
                disabled = {value === 0 ? false : true } 
                value={0}
                onClick={() => this.handleSectionChange(0)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<Favorite />}
                label="Surgery"
                disabled = {value === 1 ? false : true } 
                value={1}
                onClick={() => this.handleSectionChange(1)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PersonPinIcon />}
                label="Allergy"
                disabled = {value === 2 ? false : true } 
                value={2}
                onClick={() => this.handleSectionChange(2)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PhoneIcon />}
                label="Medication"
                disabled = {value === 3 ? false : true } 
                value={3}
                onClick={() => this.handleSectionChange(3)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<Favorite />}
                label="Medical Condition"
                disabled = {value === 4 ? false : true } 
                value={4}
                onClick={() => this.handleSectionChange(4)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PersonPinIcon />}
                label="Measurement"
                disabled = {value === 5 ? false : true } 
                value={5}
                onClick={() => this.handleSectionChange(5)}
              />
            </div>
            {/* <ScrollableTabsButtonAuto /> */}
          </Tabs>

          {value === 0 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Vaccination Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Vaccination_name"
                  value={Vaccination_name}
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="VaccinationDoctor_name"
                  value={VaccinationDoctor_name}
                  onChange={this.changeHandler}
                  required
                />
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Administration Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="VaccinationAdministration_date"
                  value={VaccinationAdministration_date}
                  type="date"
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Notes"
                  // multiline
                  // rows={2}
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="VaccinationNotes"
                  value={VaccinationNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({
                    vaccination_reminder: !vaccination_reminder,
                  });
                }}
                startIcon={<AddAlertIcon />}
              >
                Set Reminder
              </Button>

              {vaccination_reminder ? (
                <div className="addNewRecord-set-reminder">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Reminder Title"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="VaccinationTitle"
                      value={VaccinationTitle}
                      onChange={this.changeHandler}
                    />
                    <TextField
                      label="Next Appointment Date"
                      type="date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="VaccinationNext"
                      value={VaccinationNext}
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="addNewRecord-popup-wrapper-div">
                    <FormControl
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                    >
                      <InputLabel>Remind Me</InputLabel>
                      <Select
                        value={VaccinationRemind_me}
                        name="VaccinationRemind_me"
                        onChange={this.changeHandler}
                        label="Remind Me"
                      >
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="On The Same Day">
                          On The Same Day
                        </MenuItem>
                        <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                        <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                        <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                        <MenuItem value="15 Days Before">
                          15 Days Before
                        </MenuItem>
                        <MenuItem value="1 Month Before">
                          1 Month Before
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Notes"
                      // multiline
                      // rows={2}
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="VaccinationReminderNotes"
                      value={VaccinationReminderNotes}
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload /> Report
                </label>
                <input type="file"
                 onChange={(e)=>this.handleChange(e,this.state?.Vaccination_Reportlocations)}
                 multiple={false}
                 accept={[
                   ".pdf",
                   ".docx",
                   ".docs",
                   ".png",
                   ".jpg",
                   ".jpeg",
                   ".csv",
                   ".xlsx",
                 ]}
                 maxFileSize={5 * 1024 * 1024}
                >
                </input>

              
                <div className="d-flex">
                  {this.state?.Vaccination_Reportlocations?.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file?.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{file?.split("medical-records/")[1]?.slice(0, 8)}</span>
                      <button
                      type="button"
                        className="remove-button"
                        onClick={() =>
                          this.confirmboxHandel (index, "DELETE", "VACCINATION",file,vaccination_id)
                        }
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="d-flex">
                  {this.state.files.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{(file.name)?.slice(0,8)}</span>
                      <button
                      type="button"
                        className="remove-button"
                        onClick={() => this.confirmboxHandel(index,"CANCEL", "VACCINATION")}
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 1 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Surgery Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Surgery_name"
                  value={Surgery_name}
                  onChange={this.changeHandler}
                  required
                />
                <TextField
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="SurgeryDoctor_name"
                  onChange={this.changeHandler}
                  value={SurgeryDoctor_name}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Surgery Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Surgery_date"
                  type="date"
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={Surgery_date}
                />
                <FormControl
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                >
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={SurgerySeverity}
                    name="SurgerySeverity"
                    onChange={this.changeHandler}
                    label="Severity"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Minor">Minor</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Major">Major</MenuItem>
                    <MenuItem value="Critical">Critical</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Notes"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  rowsMin={2}
                  name="SurgeryNotes"
                  onChange={this.changeHandler}
                  value={SurgeryNotes}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({ surgery_reminder: !surgery_reminder });
                }}
                startIcon={<AddAlertIcon />}
              >
                Set Reminder
              </Button>

              {surgery_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="">
                          <p >Surgery</p>

                          <Button
                            className="p-0 myexp-btn"
                            onClick={() => {
                              this.setState({
                                surgery_reminder: !surgery_reminder,
                              });
                            }}
                          >
                            <ExpandMoreIcon />
                          </Button>
                        </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Reminder Title"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="SurgeryTitle"
                      onChange={this.changeHandler}
                      value={SurgeryTitle}
                    />
                    <TextField
                      label="Next Appointment Date"
                      type="date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="SurgeryNext"
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={SurgeryNext}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="SurgeryRemind_me"
                      value={SurgeryRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      label="Notes"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      rowsMin={2}
                      name="SurgeryReminderNotes"
                      onChange={this.changeHandler}
                      value={SurgeryReminderNotes}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload />
                  Report
                </label>

                <input type="file"
                   onChange={(e)=>this.handleChange(e,this.state?.Surgery_Reportlocations)}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />
                <div className="d-flex">
                  {this.state.Surgery_Reportlocations.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file?.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{file?.split("medical-records/")[1]?.slice(0, 8)}</span>
                      <button
                      type="button"
                        className="remove-button"
                        onClick={() =>
                          this.confirmboxHandel(index, "DELETE", "SURGERY",file,surgery_id)
                        }
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="d-flex">
                  {this.state.files.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{(file.name)?.slice(0,8)}</span>
                      <button
                        className="remove-button"
                        onClick={() => this.confirmboxHandel(index, "CANCEL","SURGERY")}
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                {/* {Surgery_Reportlocation ? (
                  <Tooltip title={Surgery_Reportname}>
                    <a href={Surgery_Reportlocation}>
                      <AiIcons.AiFillFileText />
                    </a>
                  </Tooltip>
                ) : null} */}
                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 2 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Allergy Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Allergy_name"
                  required
                  onChange={this.changeHandler}
                  value={Allergy_name}
                />
                <TextField
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergyDoctor_name"
                  onChange={this.changeHandler}
                  value={AllergyDoctor_name}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Diagnosis Date"
                  type="date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergyDiagnosis_date"
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={AllergyDiagnosis_date}
                />
                <TextField
                  select
                  label="Severity"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergySeverity"
                  value={AllergySeverity}
                  onChange={this.changeHandler}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="RAST 0 - Undetectable">
                    RAST 0 - Undetectable
                  </MenuItem>
                  <MenuItem value="RAST 1 - Low">RAST 1 - Low</MenuItem>
                  <MenuItem value="RAST 2- Moderate">RAST 2- Moderate</MenuItem>
                  <MenuItem value="RAST 3 - High">RAST 3 - High</MenuItem>
                  <MenuItem value="RAST 4 - Very High">
                    RAST 4 - Very High
                  </MenuItem>
                  <MenuItem value="RAST 5 - Ultra High">
                    RAST 5 - Ultra High
                  </MenuItem>
                  <MenuItem value="RAST 6 - Extremely High">
                    RAST 6 - Extremely High
                  </MenuItem>
                </TextField>
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Notes"
                  rowsMin={2}
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="AllergyNotes"
                  onChange={this.changeHandler}
                  value={AllergyNotes}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({ allergy_reminder: !allergy_reminder });
                }}
                startIcon={<AddAlertIcon />}
              >
                Set Reminder
              </Button>

              {allergy_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="">
                        <p >Allergy</p>

                        <Button
                          className="p-0 myexp-btn"
                          style={{ marginLeft: "80px" }}
                          onClick={() => {
                            this.setState({
                              allergy_reminder: !allergy_reminder,
                            });
                          }}
                        >
                          <ExpandMoreIcon />
                        </Button>
                      </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Reminder Title"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergyTitle"
                      onChange={this.changeHandler}
                      value={AllergyTitle}
                    />
                    <TextField
                      label="Next Appointment Date"
                      type="date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergyNext"
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={AllergyNext}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergyRemind_me"
                      value={AllergyRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      label="Notes"
                      rowsMin={2}
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="AllergyReminderNotes"
                      onChange={this.changeHandler}
                      value={AllergyReminderNotes}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload /> Report
                </label>

                <input type="file"
                   onChange={(e)=>this.handleChange(e,this.state?.Allergy_Reportlocations)}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                <div className="d-flex">
                  {this.state?.Allergy_Reportlocations.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file?.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                       <span className="file-name"> {file?.split("medical-records/")[1]?.slice(0, 8)}</span>
                      <button
                      type="button"
                        className="remove-button"
                        onClick={() =>
                          this.confirmboxHandel(index, "DELETE", "ALLERGY",file,allergy_id)
                        }
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="d-flex">
                  {this.state.files.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{(file.name)?.slice(0,8)}</span>
                      <button
                        className="remove-button"
                        onClick={() => this.confirmboxHandel(index,"CANCEL","ALLERGY")}
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 3 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Medication Name"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medication_name"
                  value={Medication_name}
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  type="text"
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicationDoctor_name"
                  value={MedicationDoctor_name}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="date"
                  label="Medication Start Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="medication_start_date"
                  required
                  value={Medication_startdate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.changeStartDateHandler}
                />
                <TextField
                  type="date"
                  label="Medication End Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medication_enddate"
                  value={Medication_enddate}
                  inputProps={{ min: Medication_enddate }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Dosage"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicationDosage"
                  value={MedicationDosage}
                  onChange={this.changeHandler}
                />
                <TextField
                  className="addNewRecord-popup-wrapper-div-textarea"
                  label="Notes"
                  rowsMin={2}
                  name="MedicationNotes"
                  value={MedicationNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({
                    medication_reminder: !medication_reminder,
                  });
                }}
              >
                <AddAlertIcon />
                Set Reminder
              </Button>

              {medication_reminder ? (
                <div className="addNewRecord-set-reminder">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Reminder Title"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationTitle"
                      value={MedicationTitle}
                      onChange={this.changeHandler}
                    />
                    <TextField
                      type="date"
                      label="Next Appointment Date"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationNext"
                      value={MedicationNext}
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationRemind_me"
                      value={MedicationRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      type="text"
                      label="Notes"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationReminderNotes"
                      value={MedicationReminderNotes}
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload /> Report
                </label>

                <input type="file"
                   onChange={(e)=>this.handleChange(e,this.state?.Medication_Reportlocations)}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />
                <div className="d-flex">
                  {this.state.Medication_Reportlocations.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file?.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                       <span className="file-name">{file?.split("medical-records/")[1]?.slice(0, 8)}</span>
                      <button
                      type="button"
                        className="remove-button"
                        onClick={() =>
                          this.confirmboxHandel(index, "DELETE", "MEDICATION",file,medication_id)
                        }
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="d-flex">
                  {this.state.files.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{file.name}</span>
                      <button
                        className="remove-button"
                        onClick={() => this.confirmboxHandel(index,"CANCEL", "MEDICATION")}
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 4 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Medical Condition Type"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medicalcondition_type"
                  value={Medicalcondition_type}
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  type="text"
                  label="Doctor's Name"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionDoctor_name"
                  value={MedicalconditionDoctor_name}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="date"
                  label="Diagnosis Date"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionDiagnosed_date"
                  value={MedicalconditionDiagnosed_date}
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="text"
                  label="Severity"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionSeverity"
                  value={MedicalconditionSeverity}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  className="addNewRecord-popup-wrapper-div-textarea"
                  rowsMin={2}
                  label="Notes"
                  name="MedicalconditionNotes"
                  value={MedicalconditionNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({
                    medicalcondition_reminder: !medicalcondition_reminder,
                  });
                }}
              >
                <AddAlertIcon />
                Set Reminder
              </Button>
              {medicalcondition_reminder ? (
                <div className="addNewRecord-set-reminder">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Reminder Title"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionTitle"
                      value={MedicalconditionTitle}
                      onChange={this.changeHandler}
                    />
                    <TextField
                      type="date"
                      label="Next Appointment Date"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionNext"
                      value={MedicalconditionNext}
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionRemind_me"
                      value={MedicalconditionRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      // rowsMin={2}
                      label="Notes"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="MedicalconditionReminderNotes"
                      value={MedicalconditionReminderNotes}
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <span>
                  <AiIcons.AiOutlineUpload /> Report
                </span>

                <input type="file"
                   onChange={(e)=>this.handleChange(e,this.state?.Medicalcondition_Reportlocation)}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                <div className="d-flex">
                  {this.state.Medicalcondition_Reportlocations.map(
                    (file, index) => (
                      <span
                        key={index}
                        className="file-container"
                        // style={{ maxWidth: `${file.name.length * 10}px` }}
                      >
                        {file?.type === "application/pdf" ? (
                          <AiIcons.AiFillFilePdf className="file-icon" />
                        ) : (
                          <AiIcons.AiFillFile className="file-icon" />
                        )}
                            <span className="file-name"> {file?.split("medical-records/")[1]?.slice(0, 8)}</span>
                        <button
                        type="button"
                          className="remove-button"
                          onClick={() =>
                            this.confirmboxHandel(
                              index,
                              "DELETE",
                              "MEDICALCONDITION",
                              file,
                              medicalcondition_id
                            )
                          }
                        >
                          <AiIcons.AiOutlineClose className="remove-icon" />
                        </button>
                      </span>
                    )
                  )}
                </div>
                <div className="d-flex">
                  {this.state.files.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{(file.name)?.slice(0,8)}</span>
                      <button
                        className="remove-button"
                        onClick={() =>
                          this.confirmboxHandel (index, "CANCEL","MEDICALCONDITION",file,vaccination_id)
                        }
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 5 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="number"
                  label="Weight (kg)"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Weight"
                  value={Weight}
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  type="number"
                  label="Height (cm)"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Height"
                  value={Height}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="date"
                  label="Measurement Date"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Measurement_date"
                  value={Measurement_date}
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="text"
                  label="Measured By"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Measuredby"
                  value={Measuredby}
                  onChange={this.changeHandler}
                />
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  // rowsMin={2}
                  label="Notes"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="MeasurementNotes"
                  value={MeasurementNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({
                    measurement_reminder: !measurement_reminder,
                  });
                }}
              >
                <AddAlertIcon />
                Set Reminder
              </Button>

              {measurement_reminder ? (
                <div className="addNewRecord-set-reminder">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Reminder Title"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MeasurementTitle"
                      value={MeasurementTitle}
                      onChange={this.changeHandler}
                    />
                    <TextField
                      type="date"
                      label="Next Appointment Date"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MeasurementNext"
                      value={MeasurementNext}
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MeasurementRemind_me"
                      value={MeasurementRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      // rowsMin={2}
                      label="Notes"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="MeasurementReminderNotes"
                      value={MeasurementReminderNotes}
                      onChange={this.changeHandler}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <span>
                  <AiIcons.AiOutlineUpload /> Report
                </span>

                <input type="file"
                    onChange={(e)=>this.handleChange(e,this.state?.Measurement_Reportlocations)}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                <div className="d-flex">
                  {this.state.Measurement_Reportlocations.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file?.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                         <span className="file-name"> {file?.split("medical-records/")[1]?.slice(0, 8)}</span>
                      <button
                      type="button"
                        className="remove-button"
                        onClick={() =>
                          this.confirmboxHandel (index, "DELETE", "MEASUREMENT",file,measurement_id)
                        }
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="d-flex">
                  {this.state.files.map((file, index) => (
                    <span
                      key={index}
                      className="file-container"
                      // style={{ maxWidth: `${file.name.length * 10}px` }}
                    >
                      {file.type === "application/pdf" ? (
                        <AiIcons.AiFillFilePdf className="file-icon" />
                      ) : (
                        <AiIcons.AiFillFile className="file-icon" />
                      )}
                      <span className="file-name">{file.name}</span>
                      <button
                        className="remove-button"
                        onClick={() => this.confirmboxHandel(index,"CANCEL", "MEASUREMENT")}
                      >
                        <AiIcons.AiOutlineClose className="remove-icon" />
                      </button>
                    </span>
                  ))}
                </div>
                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          <div className="addNewRecord-form-btn-wrapper">
            <button
              className="addNewRecord-form-btn"
             
              style={{ backgroundColor: "#ffc107", color: "white" }}
            >
              Submit
            </button>

            <button
            type="button"
              className="addNewRecord-form-btn"
              style={{
                color: "black",
              }}
              onClick={this.props.closeDialog}
            >
              Close
            </button>
          </div>
        </form>
        <Dialog
        open={img_remove_box}
       
        aria-describedby="alert-dialog-slide-description"
      >
      
       <div style={{padding:"1rem"}}>
       <DialogContent>
          <DialogContentText >
          Are you sure you want to permanently delete these medical records?
          </DialogContentText>
        </DialogContent>
       </div>
        <DialogActions>
          <Button onClick={()=>{this.setState({img_remove_box:false})}}>Disagree</Button>
          <Button onClick={this.fileConfirmation}>Agree</Button>
        </DialogActions>
      </Dialog>
      </>
    );
  }
}
