import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Logo from "../../../assets/genielogofinal.png";
// import CatDog from "../../../assets/catdog1.jpg";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "./viewpetdetails.component.css";
import axios from "axios";
// import blood from "../../../assets/genievectors/blood.svg";
// import color from "../../../assets/genievectors/color.svg";
// import email from "../../../assets/genievectors/email.png";
// import call from "../../../assets/genievectors/call.png";
// import phone from "../../../assets/genievectors/phone-call.png";
// import paw from "../../../assets/genievectors/animal.svg";
// import gender from "../../../assets/genievectors/gender.svg";
// import breed from "../../../assets/genievectors/breed.svg";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "@material-ui/core/Snackbar";
import axiosInstance from "../../../api/axiosinstance";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export default class ViewPetDetails extends React.Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      tag_number: localStorage.getItem("tag_number")
        ? localStorage.getItem("tag_number")
        : this.props.match.params.tag_number,
      tag_status: "",
      uid: "",
      pet_breed: "",
      pet_gender: "",
      pet_age: "",
      pet_color: "",
      address: "",
      valuable_type: "",
      showAge: "",
      pet_ident: "",
      lost_date: "",
      addressloc: "",
      pet_name: "",
      rewardcurrency: "",
      phone_one: "",
      reward: "",
      pet_imagelocation: null,
      pet_imagename: null,
      parent_firstname: "",
      parent_lastname: "",
      parent_email: "",
      pet_bloodgrp: "",
      status: "top",
    };
    this.getCoordinates = this.getCoordinates.bind(this);
  }

  componentDidMount() {
    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 20) {
        // if (this.state.status !== "scroll") {
        //   this.setState({ status: "scroll" });
        // }
        document.getElementById("genie-nav").style.backgroundColor = "white";
      } else {
        // if (this.state.status !== "top") {
        //   this.setState({ status: "top" });
        // }
        document.getElementById("genie-nav").style.backgroundColor =
          "transparent";
      }
    });

    const access_token = usertoken;

    console.log(this.props);

    const getURL = "/api/pet/getreportlocation/" + this.state.tag_number;
    axiosInstance
      .get(getURL)
      .then((response) => {
        this.setState({
          address: response.data.data[0].address,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axiosInstance
      .get("/api/pet/getPetParentDetails/" + this.state.tag_number)
      .then((response) => {
        this.setState({
          pet_imagelocation: response.data.pet_imagelocation,
          pet_imagename: response.data.pet_imagename,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const getURL1 = "/api/pet/getLostDetails/" + this.state.tag_number;
    axiosInstance
      .get(getURL1)
      .then((response) => {
        console.log(response);
        if (response.data[0].phone_one) {
          this.setState({
            valuable_type: response.data[0].valuable_type,
            pet_breed: response.data[0].pet_breed,
            pet_gender: response.data[0].pet_gender,
            pet_age: response.data[0].pet_age,
            pet_color: response.data[0].pet_color,
            pet_ident: response.data[0].pet_ident,
            pet_name: response.data[0].pet_name,
            pet_bloodgrp: response.data[0].pet_bloodgrp,
            lost_date: response.data[0].lost_date,
            phone_one: response.data[0].phone_one,
            reward: response.data[0].reward,
            rewardcurrency: response.data[0].rewardcurrency,
            parent_firstname: response.data[0].parent_firstname,
            parent_lastname: response.data[0].parent_lastname,
            parent_email: response.data[0].parent_email,
          });
        } else {
          this.setState({
            valuable_type: response.data[0].valuable_type,
            pet_breed: response.data[0].pet_breed,
            pet_gender: response.data[0].pet_gender,
            pet_age: response.data[0].pet_age,
            pet_color: response.data[0].pet_color,
            pet_ident: response.data[0].pet_ident,
            pet_name: response.data[0].pet_name,
            pet_bloodgrp: response.data[0].pet_bloodgrp,
            lost_date: response.data[0].lost_date,
            phone_one: response.data[0].parent_phone,
            reward: response.data[0].reward,
            rewardcurrency: response.data[0].rewardcurrency,
            parent_firstname: response.data[0].parent_firstname,
            parent_lastname: response.data[0].parent_lastname,
            parent_email: response.data[0].parent_email,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        axiosInstance
          .get("/api/pet/getPetParentDetails/" + this.state.tag_number)
          .then((response) => {
            this.setState({
              valuable_type: response.data.valuable_type,
              pet_breed: response.data.pet_breed,
              pet_gender: response.data.pet_gender,
              pet_age: response.data.pet_age,
              pet_color: response.data.pet_color,
              pet_ident: response.data.pet_ident,
              pet_name: response.data.pet_name,
              phone_one: response.data.parent_phone,
              parent_firstname: response.data.parent_firstname,
              parent_lastname: response.data.parent_lastname,
              parent_email: response.data.parent_email,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }

  getCoordinates(position) {
    console.log(position);
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  }

  submitHandler2 = (e) => {
    e.preventDefault();
    if (
      this.state.fullname !== "" &&
      this.state.phone !== "" &&
      this.state.phonecode !== "" &&
      this.state.email !== ""
    ) {
      console.log(this.state);
      const postURL = "/api/pet/ReportFoundUser/" + this.state.tag_number;
      axiosInstance
        .post(postURL, this.state)
        .then((res) => {
          console.log(res);
          if (res.data === "Data added") {
            window.location.href = "/#/scans/" + this.state.tag_number;
          } else {
            this.setState({
              snackbaropen: true,
              snackbarmsg: "Please try again",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log(this.state);
      this.setState({
        snackbaropen: true,
        snackbarmsg: "Please fill all the details",
      });
    }
  };

  changeHandler1 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

  viewPetDetails1 = (e) => {
    this.setState({ show: true });
  };

  render() {
    const {
      parent_firstname,
      parent_lastname,
      parent_email,
      pet_bloodgrp,
      pet_imagelocation,
      pet_breed,
      pet_gender,
      pet_age,
      pet_color,
      pet_ident,
      pet_name,
      lost_date,
      phone_one,
      reward,
      rewardcurrency,
      address,
      valuable_type,
    } = this.state;
    return (
      <div>
        <div className="genie-nav" id="genie-nav">
          <img
            src={"https://storage.googleapis.com/pettag/qr/genielogofinal.png"}
            style={{ width: "6rem" }}
          />
          <div
            style={{
              float: "right",
              padding: "1rem 1.5rem",
              borderRadius: "15px",
              backgroundColor: "wheat",
            }}
          >
            Medical Emergency ?
          </div>
        </div>

        <div id="contentToConvert" className="pb-3">
          <Card className="mb-5 mat-card">
            <div className="row text-center p5">
              {/* {valuable_type ? <p className="header-font lost-valuable header-text mt-5">LOST {valuable_type.toUpperCase()} !</p> : ""} */}
              <p className="header-font lost-valuable header-text mt-5 pb-4">
                Thank you for finding me!
              </p>
            </div>

            <div className="row ">
              <div className="col-md-5 img-container colorw">
                <div className="main-img">
                  {pet_imagelocation ? (
                    <img
                      alt=""
                      src={pet_imagelocation}
                      className="mat-card-image"
                      width="auto"
                      height="340px"
                    />
                  ) : (
                    <img
                      alt=""
                      className="mat-card-image"
                      src={
                        "https://storage.googleapis.com/pettag/qr/catdog1.jpg"
                      }
                      width="auto"
                      height="340px"
                    />
                  )}
                </div>

                <div className="card-bg">
                  <div className="name1">{toTitleCase(pet_name)}</div>

                  <div className="age1">
                    <img
                      className="vector"
                      src={
                        "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/animal.svg"
                      }
                    />
                    {pet_age} year old {toTitleCase(valuable_type)}
                  </div>
                </div>
              </div>

              <div className="col-md-7 colorw">
                <div className="first-box">
                  {pet_breed ? (
                    <div className="text-center">
                      {/* <p className="header-font lost-info-header">BREED</p> */}
                      <p className="text-center lost-info-text ">
                        {" "}
                        <img
                          classname="vector"
                          src={
                            "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/color.svg"
                          }
                        />{" "}
                        {toTitleCase(pet_color)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {pet_gender ? (
                    <div className="text-center">
                      {/* <p className="header-font lost-info-header">GENDER</p> */}
                      <p className="text-center lost-info-text">
                        {" "}
                        <img
                          classname="vector"
                          src={
                            "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/gender.svg"
                          }
                        />{" "}
                        {toTitleCase(pet_gender)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {pet_age ?
                    <div className="col text-center">
                      <p className="header-font lost-info-header">AGE</p>
                      <p className="text-center lost-info-text">{pet_age}</p>
                    </div> : ""} */}
                </div>

                <div className="first-box">
                  {pet_color ? (
                    <div className="text-center">
                      {/* <p className="header-font lost-info-header lost-info-header-res">Color</p> */}
                      <p className="text-center lost-info-text">
                        {" "}
                        <img
                          classname="vector"
                          src={
                            "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/breed.svg"
                          }
                        />{" "}
                        {toTitleCase(pet_breed)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {pet_ident ? (
                    <div className="text-center">
                      {/* <p className="header-font lost-info-header lost-info-header-res">IDENTIFICATION MARK</p> */}
                      <p className="text-center lost-info-text">
                        {" "}
                        <img
                          classname="vector"
                          src={
                            "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/blood.svg"
                          }
                        />{" "}
                        {toTitleCase(pet_bloodgrp)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="first-box">
                  {reward ? (
                    <div className="text-center">
                      {/* <p className="header-font lost-info-header lost-info-header-res">IDENTIFICATION MARK</p> */}
                      <p className="text-center lost-info-text">
                        {" "}
                        <img
                          classname="vector"
                          src={
                            "https://storage.googleapis.com/pettag/qr/money.png"
                          }
                        />
                        {toTitleCase(rewardcurrency)} {toTitleCase(reward)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row colorw"></div>
            </div>

            {lost_date || address ? (
              <div className="row mt-2 colorw text-center">
                <div style={{ width: "100%" }}>
                  <p className="header-font lost-info-header">LAST SEEN</p>

                  <p
                    className="text-center lost-info-text"
                    style={{ margin: "auto" }}
                  >
                    {lost_date}
                  </p>

                  <p className="text-center lost-info-text-address">
                    {address}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* <div className="p-3 text-center answers-to-name mt-3 mb-3">
              <h1 className="answers-to-name-header" style={{ 'wordWrap': 'break-word' }}>ANSWER TO THE NAME OF</h1>
              <h1 className="answers-to-name-header mt-3" style={{ 'wordWrap': 'break-word' }}>{pet_name}</h1>
            </div> */}

            <hr />

            {/* <div className="row text-center mb-4">
              <div className="col-md">
                <p className="bottom-text-1 mt-4">PLEASE</p>
                <p className="bottom-text-1"><span className="bottom-help-text">HELP US &nbsp; </span>FIND</p>
                <p className="bottom-text-1">{pet_name}</p>
                {
                  phone_one ?
                    <div>
                      <p className="answers-to-name-text-1 header-font text-center">Call : {parent_firstname} {parent_lastname}</p>
                      <p className="answers-to-name-text-1 header-font text-center"><a href={`https://wa.me/${phone_one}`} target="_blank">{phone_one}</a></p>
                      <p className="answers-to-name-text-1 header-font text-center">Email : {parent_email ? <a href={`mailto:${parent_email}`}>{parent_email}</a> : ''}</p>
                    </div> : ""
                }
              </div>
              {
                rewardcurrency && reward ?
                  <div className="col-md mt-4">
                    <p className="header-font reward-header bottom-text-res">REWARD</p>
                    <p className="bottom-text bottom-text-res mt-5">{rewardcurrency}<br /> <br />{reward}</p>
                  </div> : ""
              }
            </div> */}

            <div className="text-center pt-3 bottom-div">
              <p style={{ fontSize: "26px", fontFamily: "ABeeZee" }}>
                Help me return home
              </p>

              <div className="bottom-inner-div">
                {phone_one ? (
                  <div>
                    <a href={`tel:+${phone_one}`}>
                      <p className="pabr footer-font flex-between">
                        {" "}
                        <div style={{ display: "inline-block" }}>
                          <img
                            classname="vector"
                            src={
                              "https://storage.googleapis.com/pettag/qr/call.png"
                            }
                          />
                          Call Owner
                        </div>{" "}
                        <div style={{ display: "inline-block" }}>&gt;</div>
                      </p>
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {phone_one ? (
                  <div>
                    <a
                      href={`https://wa.me/${phone_one}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="pabr flex-between footer-font text-center">
                        <div style={{ display: "inline-block" }}>
                          <img
                            classname="vector"
                            src={
                              "https://storage.googleapis.com/pettag/qr/phone-call.png"
                            }
                          />
                          Whatsapp Owner
                        </div>{" "}
                        <div style={{ display: "inline-block" }}>&gt;</div>
                      </p>
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {/* <p className="answers-to-name-text-1 header-font text-center"><a href={`https://wa.me/${phone_one}`} target="_blank">{phone_one}</a></p> */}

                {parent_email ? (
                  <div>
                    <a href={`mailto:${parent_email}`}>
                      <p className="flex-between footer-font text-center pabr">
                        <div style={{ display: "inline-block" }}>
                          <img
                            classname="vector"
                            src={
                              "https://storage.googleapis.com/pettag/qr/email.png"
                            }
                          />
                          E-Mail Owner
                        </div>{" "}
                        <div style={{ display: "inline-block" }}>&gt;</div>
                      </p>
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Snackbar
                open={this.state.snackbaropen}
                autoHideDuration={8000}
                onClose={this.snackbarClose}
                message={this.state.snackbarmsg}
                action={[
                  <IconButton
                    key="close"
                    arial-label="Close"
                    color="inherit"
                    onClick={this.snackbarClose}
                  >
                    X
                  </IconButton>,
                ]}
              />
              <Dialog
                disableBackdropClick
                open={this.state.notfound}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent className="report__found__popup">
                  <h1
                    style={{
                      margin: "20px",
                      marginTop: "10px",
                      font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                      letterSpacing: "normal",
                    }}
                  >
                    Tag not Found by the specified Number
                  </h1>
                  <div className="text-center mb-4">
                    <Button variant="outlined" onClick={this.onNotFound}>
                      Ok
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                disableBackdropClick
                open={this.state.notactivated}
                onClose={this.handleClose1}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent className="report__found__popup">
                  <h1
                    style={{
                      margin: "20px",
                      marginTop: "10px",
                      font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                      letterSpacing: "normal",
                    }}
                  >
                    Tag is not activated
                  </h1>
                  <div className="text-center mb-4">
                    <Button variant="outlined" onClick={this.onActivated}>
                      Ok
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                className="emerd-dilog"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                fullWidth
                maxWidth="sm"
                disableBackdropClick
                open={this.state.show}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent className="inner-emerd-div">
                  <div
                    className="inner-dialog-div"
                    style={{ padding: "0px", margin: "0px" }}
                  >
                    <h1
                      style={{
                        margin: "15px",
                        font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                        letterSpacing: "normal",
                      }}
                    >
                      Please enter the following details.
                    </h1>
                    <form
                      className="emerg_call"
                      style={{
                        margin: "5px 25px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onSubmit={this.submitHandler2}
                    >
                      <label className="repFound-label">Full Name *</label>
                      <input
                        name="fullname"
                        className="repFound-input"
                        placeholder="Full Name"
                        onChange={this.changeHandler1}
                        required
                      ></input>
                      <br />
                      <br />
                      {/* style={{ display: 'flex',flexDirection:'row' }} 
                         display: 'flex', flexDirection: 'column', flex: "0.5",
                         className="row2"*/}
                      <div
                        className="row2"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="counrty__div">
                          <label className="repFound-label">
                            Country Code *
                          </label>
                          <select
                            name="phonecode"
                            className="repFound-input ch-cont-code"
                            style={{
                              textAlign: "left",
                              height: "33px",
                              backgroundColor: "white",
                            }}
                            onChange={this.changeHandler1}
                            required
                          >
                            <option value="" disabled selected>
                              Choose country code
                            </option>
                            <option data-countryCode="IN" value="+91">
                              India (+91)
                            </option>
                            <option data-countryCode="DZ" value="+213">
                              Algeria (+213)
                            </option>
                            <option data-countryCode="AD" value="+376">
                              Andorra (+376)
                            </option>
                            <option data-countryCode="AO" value="+244">
                              Angola (+244)
                            </option>
                            <option data-countryCode="AI" value="+1264">
                              Anguilla (+1264)
                            </option>
                            <option data-countryCode="AG" value="+1268">
                              Antigua &amp; Barbuda (+1268)
                            </option>
                            <option data-countryCode="AR" value="+54">
                              Argentina (+54)
                            </option>
                            <option data-countryCode="AM" value="+374">
                              Armenia (+374)
                            </option>
                            <option data-countryCode="AW" value="+297">
                              Aruba (+297)
                            </option>
                            <option data-countryCode="AU" value="+61">
                              Australia (+61)
                            </option>
                            <option data-countryCode="AT" value="+43">
                              Austria (+43)
                            </option>
                            <option data-countryCode="AZ" value="+994">
                              Azerbaijan (+994)
                            </option>
                            <option data-countryCode="BS" value="+1242">
                              Bahamas (+1242)
                            </option>
                            <option data-countryCode="BH" value="+973">
                              Bahrain (+973)
                            </option>
                            <option data-countryCode="BD" value="+880">
                              Bangladesh (+880)
                            </option>
                            <option data-countryCode="BB" value="+1246">
                              Barbados (+1246)
                            </option>
                            <option data-countryCode="BY" value="+375">
                              Belarus (+375)
                            </option>
                            <option data-countryCode="BE" value="+32">
                              Belgium (+32)
                            </option>
                            <option data-countryCode="BZ" value="+501">
                              Belize (+501)
                            </option>
                            <option data-countryCode="BJ" value="+229">
                              Benin (+229)
                            </option>
                            <option data-countryCode="BM" value="+1441">
                              Bermuda (+1441)
                            </option>
                            <option data-countryCode="BT" value="+975">
                              Bhutan (+975)
                            </option>
                            <option data-countryCode="BO" value="+591">
                              Bolivia (+591)
                            </option>
                            <option data-countryCode="BA" value="+387">
                              Bosnia Herzegovina (+387)
                            </option>
                            <option data-countryCode="BW" value="+267">
                              Botswana (+267)
                            </option>
                            <option data-countryCode="BR" value="+55">
                              Brazil (+55)
                            </option>
                            <option data-countryCode="BN" value="+673">
                              Brunei (+673)
                            </option>
                            <option data-countryCode="BG" value="+359">
                              Bulgaria (+359)
                            </option>
                            <option data-countryCode="BF" value="+226">
                              Burkina Faso (+226)
                            </option>
                            <option data-countryCode="BI" value="+257">
                              Burundi (+257)
                            </option>
                            <option data-countryCode="KH" value="+855">
                              Cambodia (+855)
                            </option>
                            <option data-countryCode="CM" value="+237">
                              Cameroon (+237)
                            </option>
                            <option data-countryCode="CA" value="+1">
                              Canada (+1)
                            </option>
                            <option data-countryCode="CV" value="+238">
                              Cape Verde Islands (+238)
                            </option>
                            <option data-countryCode="KY" value="+1345">
                              Cayman Islands (+1345)
                            </option>
                            <option data-countryCode="CF" value="+236">
                              Central African Republic (+236)
                            </option>
                            <option data-countryCode="CL" value="+56">
                              Chile (+56)
                            </option>
                            <option data-countryCode="CN" value="+86">
                              China (+86)
                            </option>
                            <option data-countryCode="CO" value="+57">
                              Colombia (+57)
                            </option>
                            <option data-countryCode="KM" value="+269">
                              Comoros (+269)
                            </option>
                            <option data-countryCode="CG" value="+242">
                              Congo (+242)
                            </option>
                            <option data-countryCode="CK" value="+682">
                              Cook Islands (+682)
                            </option>
                            <option data-countryCode="CR" value="+506">
                              Costa Rica (+506)
                            </option>
                            <option data-countryCode="HR" value="+385">
                              Croatia (+385)
                            </option>
                            <option data-countryCode="CU" value="+53">
                              Cuba (+53)
                            </option>
                            <option data-countryCode="CY" value="+90392">
                              Cyprus North (+90392)
                            </option>
                            <option data-countryCode="CY" value="+357">
                              Cyprus South (+357)
                            </option>
                            <option data-countryCode="CZ" value="+42">
                              Czech Republic (+42)
                            </option>
                            <option data-countryCode="DK" value="+45">
                              Denmark (+45)
                            </option>
                            <option data-countryCode="DJ" value="+253">
                              Djibouti (+253)
                            </option>
                            <option data-countryCode="DM" value="+1809">
                              Dominica (+1809)
                            </option>
                            <option data-countryCode="DO" value="+1809">
                              Dominican Republic (+1809)
                            </option>
                            <option data-countryCode="EC" value="+593">
                              Ecuador (+593)
                            </option>
                            <option data-countryCode="EG" value="+20">
                              Egypt (+20)
                            </option>
                            <option data-countryCode="SV" value="+503">
                              El Salvador (+503)
                            </option>
                            <option data-countryCode="GQ" value="+240">
                              Equatorial Guinea (+240)
                            </option>
                            <option data-countryCode="ER" value="+291">
                              Eritrea (+291)
                            </option>
                            <option data-countryCode="EE" value="+372">
                              Estonia (+372)
                            </option>
                            <option data-countryCode="ET" value="+251">
                              Ethiopia (+251)
                            </option>
                            <option data-countryCode="FK" value="+500">
                              Falkland Islands (+500)
                            </option>
                            <option data-countryCode="FO" value="+298">
                              Faroe Islands (+298)
                            </option>
                            <option data-countryCode="FJ" value="+679">
                              Fiji (+679)
                            </option>
                            <option data-countryCode="FI" value="+358">
                              Finland (+358)
                            </option>
                            <option data-countryCode="FR" value="+33">
                              France (+33)
                            </option>
                            <option data-countryCode="GF" value="+594">
                              French Guiana (+594)
                            </option>
                            <option data-countryCode="PF" value="+689">
                              French Polynesia (+689)
                            </option>
                            <option data-countryCode="GA" value="+241">
                              Gabon (+241)
                            </option>
                            <option data-countryCode="GM" value="+220">
                              Gambia (+220)
                            </option>
                            <option data-countryCode="GE" value="+7880">
                              Georgia (+7880)
                            </option>
                            <option data-countryCode="DE" value="+49">
                              Germany (+49)
                            </option>
                            <option data-countryCode="GH" value="+233">
                              Ghana (+233)
                            </option>
                            <option data-countryCode="GI" value="+350">
                              Gibraltar (+350)
                            </option>
                            <option data-countryCode="GR" value="+30">
                              Greece (+30)
                            </option>
                            <option data-countryCode="GL" value="+299">
                              Greenland (+299)
                            </option>
                            <option data-countryCode="GD" value="+1473">
                              Grenada (+1473)
                            </option>
                            <option data-countryCode="GP" value="+590">
                              Guadeloupe (+590)
                            </option>
                            <option data-countryCode="GU" value="+671">
                              Guam (+671)
                            </option>
                            <option data-countryCode="GT" value="+502">
                              Guatemala (+502)
                            </option>
                            <option data-countryCode="GN" value="+224">
                              Guinea (+224)
                            </option>
                            <option data-countryCode="GW" value="+245">
                              Guinea - Bissau (+245)
                            </option>
                            <option data-countryCode="GY" value="+592">
                              Guyana (+592)
                            </option>
                            <option data-countryCode="HT" value="+509">
                              Haiti (+509)
                            </option>
                            <option data-countryCode="HN" value="+504">
                              Honduras (+504)
                            </option>
                            <option data-countryCode="HK" value="+852">
                              Hong Kong (+852)
                            </option>
                            <option data-countryCode="HU" value="+36">
                              Hungary (+36)
                            </option>
                            <option data-countryCode="IS" value="+354">
                              Iceland (+354)
                            </option>
                            <option data-countryCode="ID" value="+62">
                              Indonesia (+62)
                            </option>
                            <option data-countryCode="IR" value="+98">
                              Iran (+98)
                            </option>
                            <option data-countryCode="IQ" value="+964">
                              Iraq (+964)
                            </option>
                            <option data-countryCode="IE" value="+353">
                              Ireland (+353)
                            </option>
                            <option data-countryCode="IL" value="+972">
                              Israel (+972)
                            </option>
                            <option data-countryCode="IT" value="+39">
                              Italy (+39)
                            </option>
                            <option data-countryCode="JM" value="+1876">
                              Jamaica (+1876)
                            </option>
                            <option data-countryCode="JP" value="+81">
                              Japan (+81)
                            </option>
                            <option data-countryCode="JO" value="+962">
                              Jordan (+962)
                            </option>
                            <option data-countryCode="KZ" value="+7">
                              Kazakhstan (+7)
                            </option>
                            <option data-countryCode="KE" value="+254">
                              Kenya (+254)
                            </option>
                            <option data-countryCode="KI" value="+686">
                              Kiribati (+686)
                            </option>
                            <option data-countryCode="KP" value="+850">
                              Korea North (+850)
                            </option>
                            <option data-countryCode="KR" value="+82">
                              Korea South (+82)
                            </option>
                            <option data-countryCode="KW" value="+965">
                              Kuwait (+965)
                            </option>
                            <option data-countryCode="KG" value="+996">
                              Kyrgyzstan (+996)
                            </option>
                            <option data-countryCode="LA" value="+856">
                              Laos (+856)
                            </option>
                            <option data-countryCode="LV" value="+371">
                              Latvia (+371)
                            </option>
                            <option data-countryCode="LB" value="+961">
                              Lebanon (+961)
                            </option>
                            <option data-countryCode="LS" value="+266">
                              Lesotho (+266)
                            </option>
                            <option data-countryCode="LR" value="+231">
                              Liberia (+231)
                            </option>
                            <option data-countryCode="LY" value="+218">
                              Libya (+218)
                            </option>
                            <option data-countryCode="LI" value="+417">
                              Liechtenstein (+417)
                            </option>
                            <option data-countryCode="LT" value="+370">
                              Lithuania (+370)
                            </option>
                            <option data-countryCode="LU" value="+352">
                              Luxembourg (+352)
                            </option>
                            <option data-countryCode="MO" value="+853">
                              Macao (+853)
                            </option>
                            <option data-countryCode="MK" value="+389">
                              Macedonia (+389)
                            </option>
                            <option data-countryCode="MG" value="+261">
                              Madagascar (+261)
                            </option>
                            <option data-countryCode="MW" value="+265">
                              Malawi (+265)
                            </option>
                            <option data-countryCode="MY" value="+60">
                              Malaysia (+60)
                            </option>
                            <option data-countryCode="MV" value="+960">
                              Maldives (+960)
                            </option>
                            <option data-countryCode="ML" value="+223">
                              Mali (+223)
                            </option>
                            <option data-countryCode="MT" value="+356">
                              Malta (+356)
                            </option>
                            <option data-countryCode="MH" value="+692">
                              Marshall Islands (+692)
                            </option>
                            <option data-countryCode="MQ" value="+596">
                              Martinique (+596)
                            </option>
                            <option data-countryCode="MR" value="+222">
                              Mauritania (+222)
                            </option>
                            <option data-countryCode="YT" value="+269">
                              Mayotte (+269)
                            </option>
                            <option data-countryCode="MX" value="+52">
                              Mexico (+52)
                            </option>
                            <option data-countryCode="FM" value="+691">
                              Micronesia (+691)
                            </option>
                            <option data-countryCode="MD" value="+373">
                              Moldova (+373)
                            </option>
                            <option data-countryCode="MC" value="+377">
                              Monaco (+377)
                            </option>
                            <option data-countryCode="MN" value="+976">
                              Mongolia (+976)
                            </option>
                            <option data-countryCode="MS" value="+1664">
                              Montserrat (+1664)
                            </option>
                            <option data-countryCode="MA" value="+212">
                              Morocco (+212)
                            </option>
                            <option data-countryCode="MZ" value="+258">
                              Mozambique (+258)
                            </option>
                            <option data-countryCode="MN" value="+95">
                              Myanmar (+95)
                            </option>
                            <option data-countryCode="NA" value="+264">
                              Namibia (+264)
                            </option>
                            <option data-countryCode="NR" value="+674">
                              Nauru (+674)
                            </option>
                            <option data-countryCode="NP" value="+977">
                              Nepal (+977)
                            </option>
                            <option data-countryCode="NL" value="+31">
                              Netherlands (+31)
                            </option>
                            <option data-countryCode="NC" value="+687">
                              New Caledonia (+687)
                            </option>
                            <option data-countryCode="NZ" value="+64">
                              New Zealand (+64)
                            </option>
                            <option data-countryCode="NI" value="+505">
                              Nicaragua (+505)
                            </option>
                            <option data-countryCode="NE" value="+227">
                              Niger (+227)
                            </option>
                            <option data-countryCode="NG" value="+234">
                              Nigeria (+234)
                            </option>
                            <option data-countryCode="NU" value="+683">
                              Niue (+683)
                            </option>
                            <option data-countryCode="NF" value="+672">
                              Norfolk Islands (+672)
                            </option>
                            <option data-countryCode="NP" value="+670">
                              Northern Marianas (+670)
                            </option>
                            <option data-countryCode="NO" value="+47">
                              Norway (+47)
                            </option>
                            <option data-countryCode="OM" value="+968">
                              Oman (+968)
                            </option>
                            <option data-countryCode="PW" value="+680">
                              Palau (+680)
                            </option>
                            <option data-countryCode="PA" value="+507">
                              Panama (+507)
                            </option>
                            <option data-countryCode="PG" value="+675">
                              Papua New Guinea (+675)
                            </option>
                            <option data-countryCode="PY" value="+595">
                              Paraguay (+595)
                            </option>
                            <option data-countryCode="PE" value="+51">
                              Peru (+51)
                            </option>
                            <option data-countryCode="PH" value="+63">
                              Philippines (+63)
                            </option>
                            <option data-countryCode="PL" value="+48">
                              Poland (+48)
                            </option>
                            <option data-countryCode="PT" value="+351">
                              Portugal (+351)
                            </option>
                            <option data-countryCode="PR" value="+1787">
                              Puerto Rico (+1787)
                            </option>
                            <option data-countryCode="QA" value="+974">
                              Qatar (+974)
                            </option>
                            <option data-countryCode="RE" value="+262">
                              Reunion (+262)
                            </option>
                            <option data-countryCode="RO" value="+40">
                              Romania (+40)
                            </option>
                            <option data-countryCode="RU" value="+7">
                              Russia (+7)
                            </option>
                            <option data-countryCode="RW" value="+250">
                              Rwanda (+250)
                            </option>
                            <option data-countryCode="SM" value="+378">
                              San Marino (+378)
                            </option>
                            <option data-countryCode="ST" value="+239">
                              Sao Tome &amp; Principe (+239)
                            </option>
                            <option data-countryCode="SA" value="+966">
                              Saudi Arabia (+966)
                            </option>
                            <option data-countryCode="SN" value="+221">
                              Senegal (+221)
                            </option>
                            <option data-countryCode="CS" value="+381">
                              Serbia (+381)
                            </option>
                            <option data-countryCode="SC" value="+248">
                              Seychelles (+248)
                            </option>
                            <option data-countryCode="SL" value="+232">
                              Sierra Leone (+232)
                            </option>
                            <option data-countryCode="SG" value="+65">
                              Singapore (+65)
                            </option>
                            <option data-countryCode="SK" value="+421">
                              Slovak Republic (+421)
                            </option>
                            <option data-countryCode="SI" value="+386">
                              Slovenia (+386)
                            </option>
                            <option data-countryCode="SB" value="+677">
                              Solomon Islands (+677)
                            </option>
                            <option data-countryCode="SO" value="+252">
                              Somalia (+252)
                            </option>
                            <option data-countryCode="ZA" value="+27">
                              South Africa (+27)
                            </option>
                            <option data-countryCode="ES" value="+34">
                              Spain (+34)
                            </option>
                            <option data-countryCode="LK" value="+94">
                              Sri Lanka (+94)
                            </option>
                            <option data-countryCode="SH" value="+290">
                              St. Helena (+290)
                            </option>
                            <option data-countryCode="KN" value="+1869">
                              St. Kitts (+1869)
                            </option>
                            <option data-countryCode="SC" value="+1758">
                              St. Lucia (+1758)
                            </option>
                            <option data-countryCode="SD" value="+249">
                              Sudan (+249)
                            </option>
                            <option data-countryCode="SR" value="+597">
                              Suriname (+597)
                            </option>
                            <option data-countryCode="SZ" value="+268">
                              Swaziland (+268)
                            </option>
                            <option data-countryCode="SE" value="+46">
                              Sweden (+46)
                            </option>
                            <option data-countryCode="CH" value="+41">
                              Switzerland (+41)
                            </option>
                            <option data-countryCode="SI" value="+963">
                              Syria (+963)
                            </option>
                            <option data-countryCode="TW" value="+886">
                              Taiwan (+886)
                            </option>
                            <option data-countryCode="TJ" value="+7">
                              Tajikstan (+7)
                            </option>
                            <option data-countryCode="TH" value="+66">
                              Thailand (+66)
                            </option>
                            <option data-countryCode="TG" value="+228">
                              Togo (+228)
                            </option>
                            <option data-countryCode="TO" value="+676">
                              Tonga (+676)
                            </option>
                            <option data-countryCode="TT" value="+1868">
                              Trinidad &amp; Tobago (+1868)
                            </option>
                            <option data-countryCode="TN" value="+216">
                              Tunisia (+216)
                            </option>
                            <option data-countryCode="TR" value="+90">
                              Turkey (+90)
                            </option>
                            <option data-countryCode="TM" value="+7">
                              Turkmenistan (+7)
                            </option>
                            <option data-countryCode="TM" value="+993">
                              Turkmenistan (+993)
                            </option>
                            <option data-countryCode="TC" value="+1649">
                              Turks &amp; Caicos Islands (+1649)
                            </option>
                            <option data-countryCode="TV" value="+688">
                              Tuvalu (+688)
                            </option>
                            <option data-countryCode="UG" value="+256">
                              Uganda (+256)
                            </option>
                            <option data-countryCode="GB" value="+44">
                              UK (+44)
                            </option>
                            <option data-countryCode="UA" value="+380">
                              Ukraine (+380)
                            </option>
                            <option data-countryCode="AE" value="+971">
                              United Arab Emirates (+971)
                            </option>
                            <option data-countryCode="UY" value="+598">
                              Uruguay (+598)
                            </option>
                            <option data-countryCode="US" value="+1">
                              USA (+1)
                            </option>
                            <option data-countryCode="UZ" value="+7">
                              Uzbekistan (+7)
                            </option>
                            <option data-countryCode="VU" value="+678">
                              Vanuatu (+678)
                            </option>
                            <option data-countryCode="VA" value="+379">
                              Vatican City (+379)
                            </option>
                            <option data-countryCode="VE" value="+58">
                              Venezuela (+58)
                            </option>
                            <option data-countryCode="VN" value="+84">
                              Vietnam (+84)
                            </option>
                            <option data-countryCode="VG" value="+1284">
                              Virgin Islands - British (+1284)
                            </option>
                            <option data-countryCode="VI" value="+1340">
                              Virgin Islands - US (+1340)
                            </option>
                            <option data-countryCode="WF" value="+681">
                              Wallis &amp; Futuna (+681)
                            </option>
                            <option data-countryCode="YE" value="+969">
                              Yemen (North)(+969)
                            </option>
                            <option data-countryCode="YE" value="+967">
                              Yemen (South)(+967)
                            </option>
                            <option data-countryCode="ZM" value="+260">
                              Zambia (+260)
                            </option>
                            <option data-countryCode="ZW" value="+263">
                              Zimbabwe (+263)
                            </option>
                          </select>
                        </div>
                        {/*  style={{display:'flex',flexDirection:'coloumn'}} 
                            className="mobile-num"*/}
                        <div className="mobnum__div">
                          <label className="repFound-label mob-number">
                            Mobile Number *
                          </label>
                          <input
                            style={{ width: "150px" }}
                            name="phone"
                            className="repFound-input"
                            onChange={this.changeHandler1}
                            required
                          ></input>
                          <br />
                          <br />
                        </div>
                      </div>
                      <label className="repFound-label email-rep-found">
                        Email ID*
                      </label>
                      <input
                        className="report_found_email repFound-input"
                        type="email"
                        name="email"
                        onChange={this.changeHandler1}
                        required
                      ></input>
                    </form>
                    <div
                      className="mt-4 my-d-btn"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "7px",
                        marginTop: "15px",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="otp-buttons bg-warning confirm"
                        style={{
                          padding: "5px 10px",
                          margin: "10px 20px",
                          marginBottom: "35px",
                          flex: 0.5,
                          border: "none",
                        }}
                        onClick={this.submitHandler2}
                      >
                        CONFIRM
                      </Button>
                      <Button
                        variant="contained"
                        className="otp-buttons cancel"
                        style={{
                          margin: "10px 20px",
                          marginBottom: "35px",
                          flex: 0.5,
                          border: "none",
                        }}
                        onClick={this.handleClose}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            {/* <hr /> */}
            {/* <div className="row text-center bottom-div">
              <img src={Logo} className="mb-3 text-center" width="100px" alt="" style={{ margin: 'auto' }} />
            </div> */}
          </Card>
        </div>
      </div>
    );
  }
}
