import React from "react";
import "../editparentdetails/editparentdetails.component.css";
// import CatDog from "../../assets/user2.png";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
// import Snackbar from "@material-ui/core/Snackbar";
import {Snackbar} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Sidebarcontent from "../sidebar/sidebar.component.jsx";
import CloseIcon from "@material-ui/icons/Close";
import { QrReader } from "react-qr-reader";
import * as FcIcons from "react-icons/fc";
import Alert from '@mui/material/Alert';
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import axios from "axios";
import Navbar from "../header/header.component.jsx";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
import {
  Breadcrumbs,
  FormControl,
  FormLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Country, State, City } from "country-state-city";
import axiosInstance from "../../api/axiosinstance.js";

const baseURL = switchUrls("genie");
const gatewayBaseURL = switchUrls("gateway");
const basegatewayURL = switchUrls("gateway");

const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
const uid = localStorage.getItem("uid");

export default class EditParentDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      parent_imagelocation: null,
      parent_imagename: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      parent_firstname: "",
      parent_lastname: "",
      parent_phone: "",
      parent_phonecode: "",
      parent_altphone: "",
      parent_altphonecode: "",
      parent_email: "",
      parent_altemail: "",
      parent_address: "",
      parent_country: "",
      parent_state: "",
      parent_city: "",
      parent_pincode: "",
      parent_gender: "",
      message: "",
      redirecttomedicalpage: false,
      key_imagelocation: null,
      sidebar: true,
      subMenu: true,
      showLoader: false,
      show: false,
      showQR: false,
      tagNotFound: false,
      alreadyActivated: false,
      walletDialogOpen: false,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAllTags = this.handleAllTags.bind(this);
    this.handleActiveInactive = this.handleActiveInactive.bind(this);
    this.handleLostFound = this.handleLostFound.bind(this); 
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateTag = this.activateTag.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleQRResult = this.handleQRResult.bind(this);
    this.walletDialogHandleClose = this.walletDialogHandleClose.bind(this);
  }

  componentDidMount() {
    axiosInstance
      .get(
        "/api/pet/getPetParentDetails/" +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log("response: ", response);
        this.setState({
          uid: response.data.uid,
          parent_imagelocation: response.data.parent_imagelocation,
          parent_imagename: response.data.parent_imagename,
          // tag_number: response.data.tag_number,
          parent_firstname: response.data.parent_firstname,
          parent_lastname: response.data.parent_lastname,
          parent_phone: response.data.parent_phone,
          parent_phonecode: response.data.parent_phonecode?.match(/(\d+)/)[0],
          parent_altphone: response.data.parent_altphone,
          parent_altphonecode:
            response.data.parent_altphonecode?.match(/(\d+)/)[0],
          parent_email: response.data.parent_email,
          parent_altemail: response.data.parent_altemail,
          parent_address: response.data.parent_address,
          parent_country: response.data.parent_country,
          parent_state: response.data.parent_state,
          parent_city: response.data.parent_city,
          parent_pincode: response.data.parent_pincode,
          parent_gender: response.data.parent_gender,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });

    axiosInstance
      .get(
        "/api/pet/countMedicalDetails/" +
          this.props.match.params.tag_number
      )
      .then((response) => {
        let count = response.data;
        if (
          count.Vaccination === 0 &&
          count.Allergy === 0 &&
          count.Surgery === 0 &&
          count.Medication === 0 &&
          count.Medicalcondition === 0 &&
          count.Measurement === 0
        ) {
          this.setState({ redirecttomedicalpage: true });
        } else {
          this.setState({ redirecttomedicalpage: false });
        }
      });
  }

  handleChange(event) {
    if (event.target.files[0]) {
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  changeHandler = (e) => {
    // console.log("event: ", e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose() {
    this.setState({ show: false });
  }
  handleInputChange(e) {
    const inputValue = e.target.value
      .replace(/\s/g, "")
      .toUpperCase()
      .slice(0, 10);
    this.setState({ tag_number: inputValue });
  }
  phoneNumberChangeHandler = (value, details) => {
    let { dialCode } = details;
    if (dialCode && value) {
      const phCode = "+" + dialCode;
      const phoneNumber = value.replace(phCode, "");
      this.setState({
        parent_phone: phoneNumber,
        parent_phonecode: phCode,
      });
    }
  };

  alternatePhoneNumberChangeHandler = (value, details) => {
    let { dialCode } = details;
    if (dialCode && value) {
      const phCode = "+" + dialCode;
      const phoneNumber = value.replace(phCode, "");
      this.setState({
        parent_altphone: phoneNumber,
        parent_altphonecode: phCode,
      });
    }
  };

  deleteimage = (e) => {
    axiosInstance
      .post("/api/pet/deleteimage", this.state)
      .then((response) => {
        if (response.data === "Parent Image removed") {
          this.setState({
            imageUrl:
              "https://storage.googleapis.com/pettag/qrtagdev/assets/user2.png",
            parent_imagelocation: null,
            parent_imagename: null,
          });
        } else if (response.data === "wrong data") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
    this.setState({
      imageUrl:
        "https://storage.googleapis.com/pettag/qrtagdev/assets/user2.png",
      pet_imagelocation: null,
      pet_imagename: null,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    axiosInstance
      .post(
        "/api/pet/parentdetails/" +
          this.props.match.params.tag_number,
        this.state,
       
      )
      .then((response) => {
        if (response.data === "Pet Parent Details Updated") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Pet Parent Details Updated",
          });

          this.state.redirecttomedicalpage
                ? (window.location.href =
                    "/#/medical-dashboard/" +
                    this.props.match.params.tag_number)
                : (window.location.href = `${gatewayBaseURL}/#/dashboard/${localStorage.getItem("uid")}`);

         
        } else if (response.data === "wrong data") {
          this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
        }
      })
      .catch((error) => {
        console.error(error.message);

        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
  };
  activateTag() {
    const { tag_number } = this.state;
    const state = {
      tag_number: tag_number.toUpperCase(),
    };
    this.setState({ showLoader: true });
    const loaderTimer = setTimeout(() => {
      this.setState({ showLoader: false });
    }, 5000);
  
    axios
      .post(`${basegatewayURL}/api/checkTagNumberWithoutHeader`, state, {
        headers: {
          Authorization: this.props.token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const productcategory = res.data.tag_data.product_category;
        sessionStorage.removeItem("tag_number");
  
        // Check if product category starts with "Dolphin" or "Smart"
        if (
          productcategory.startsWith("Dolphin") || 
          productcategory.startsWith("Smart")
        ) {
          this.setState({ walletDialogOpen: true,showLoader: false });
          return;
        }
  
        const routes = {
          "QR PET": `/pet-activation/${tag_number}`,
          "QR Key": `/key-activation/${tag_number}`,
          "QR bag": `/bag-activation/${tag_number}`,
          "QR Bag": `/bag-activation/${tag_number}`,
          "E-Bag": `/bag-activation/${tag_number}`,
          "QR Medical": `/bag-activation/${tag_number}`,
          "QR Passport": `/passport-activation/${tag_number}`,
          "E-Passport": `/passport-activation/${tag_number}`,
          "QR Gadget": `/earphone-activation/${tag_number}`,
          "Generic": `/generic-activation/${tag_number}`,
          "QR Wallet": `/wallet-activation/${tag_number}`,
          "QR Human": `/human-activation/${tag_number}`,
          "QR HUMAN": `/human-activation/${tag_number}`,
          "QR Vehicle": `/#/vehicle-activation/${tag_number}`,
        };
  
        if (routes[productcategory]) {
          window.location.href = routes[productcategory];
        }
      })
      .catch((error) => {
        clearTimeout(loaderTimer);
        this.setState({ showLoader: false });
        const responseData = error.response.data;
        console.log(responseData, "data");
        if (responseData === "Tag not found by the specified number.") {
          // this.setState({ tagNotFound: true });
          this.setState({
              tagNotFound: true,
              snackbarOpen: true,
              snackbarMessage: "Tag not found!",
              snackbarType: "warning",
            });
        } else if (responseData === "Tag is already activated.") {
          // this.setState({ show: false, alreadyActivated: true });
          this.setState({
              alreadyActivated: true,
              snackbarOpen: true,
              snackbarMessage: "Tag is already activated.",
              snackbarType: "info",
            });
        }
      });
     }
  
  handleCloseQRModal() {
      this.setState({ showQR: false });
    }
     walletDialogHandleClose = () => {
      this.setState({ walletDialogOpen: false });
    };
  
    handleQRResult(result, error) {
      if (result) {
        const activateTag = result?.text
          .split("/")
          [result?.text.split("/").length - 1].toUpperCase();
        this.setState({ activateTag, showQR: false });
      }
      if (error) {
        console.error(error.message);
      }
    }
  
    renderQRModal() {
      return (
        <Dialog
          open={this.state.showQR}
          fullWidth
          maxWidth="xs"
          onClose={this.handleCloseQRModal}
          aria-labelledby={"Scan QR"}
        >
          <QrReader
            constraints={{
              facingMode: "environment",
              focusMode: "continuous",
              zoom: 4.0,
            }}
            onResult={this.handleQRResult}
            style={{ width: "100%" }}
          />
        </Dialog>
      );
    }
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  // Breadcrumb handle click
  swithBreadcrumbs = (e, tab) => {
    if (tab === "PET_DETAILS") {
      window.location.href =
        "/#/pet-details/" + this.props.match.params.tag_number;
    }
  };
  handleLostFound() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleAllTags() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleActiveInactive() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }
  render() {
    const tagn = this.props.match.params.tag_number;

    const {
      sidebar,
      subMenu,
      key_imagelocation,
      parent_imagelocation,
      imageUrl,
      parent_gender,
      parent_imagename,
      uid,
      tag_number,
      parent_firstname,
      parent_lastname,
      parent_phone,
      parent_phonecode,
      parent_altphone,
      parent_altphonecode,
      parent_email,
      parent_altemail,
      parent_address,
      parent_country,
      parent_state,
      parent_city,
      parent_pincode,
      show,
      walletDialogOpen,
      snackbarOpen,
      snackbarMessage,
      snackbarType,
    } = this.state;
    // console.log("this.state::", this.state);
    let capFirstLetter = (sentenses) => {
      const arr = sentenses?.split(" ");
      for (let i = 0; i < arr?.length; i++) {
        arr[i] = arr[i]?.charAt(0).toUpperCase() + arr[i]?.slice(1);
      }
      return arr?.join(" ");
    };

    let countryWithIsoCode = Country?.getAllCountries().filter((v) => {
      return v.name === parent_country;
    });
    console.log("countryWithIsoCode: ", countryWithIsoCode);

    let getStateIsoCode = State?.getStatesOfCountry(
      countryWithIsoCode[0]?.isoCode
    ).filter((v) => {
      return v.name === parent_state;
    });
    console.log("stateWithIsoCode: ", getStateIsoCode);

    // const cities = City.getCitiesOfState( countryWithIsoCode[0]?.isoCode, getStateIsoCode[0]?.isoCode)
    // console.log("cities: ", cities);

    return (
      <>
        <Navbar />

        <div className="editParentDetails-main-wrapper">
          <div className="editParentDetails-left-wrapper">
          <Sidebarcontent
              handleShow={this.handleShow}
              handleAllTags={this.handleAllTags}
              handleActiveInactive={this.handleActiveInactive}
              handleLostFound={this.handleLostFound}
              extraButtonStyle="extraButtonStyle"
            />
             <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => this.setState({ showQR: true })}  
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={this.activatetag}
                    onChange={this.handleInputChange}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="dashboard-verifyOtp-button"
                    onClick={this.activateTag}
                  >
                    ACTIVATE
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
        PaperProps={{
          style: { borderRadius: 15,padding: '1.5em',position:'relative' },
        }}
        open={walletDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
                aria-label="close"
                onClick={this.walletDialogHandleClose}
                style={{
                    position: 'absolute !impotant' ,
                    top: '10px !impotant',
                    right: '10px !impotant',
                    zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
                <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  }style={{ color: "blue" }}><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  } style={{ color: "blue" }}>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions> 
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => this.setState({ snackbarOpen: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "580px", // Optionally adjust margin
          width: "100%",
        }}
      >
        <Alert
          severity={snackbarType}
          onClose={() => this.setState({ snackbarOpen: false })}
          sx={{
            height: "50px",
            fontSize: "1.2rem",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
          </div>

          <div className="editParentDetails-right-wrapper">
            <div id="breadcrumbs" className="mb-3">
              <Breadcrumbs
                className="mui-breadcrumb"
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Link
                  className="breadcrumb-link"
                  color="inherit"
                  onClick={(e) => this.swithBreadcrumbs(e, "PET_DETAILS")}
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    color: "#fcbc1b",
                  }}
                >
                  Pet Details
                </Link>
                <Link
                  className="breadcrumb-link"
                  color="textPrimary"
                  onClick={(e) =>
                    this.swithBreadcrumbs(e, "PET_PARENT_DETAILS")
                  }
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "12px",
                    color: "#fcbc1b",
                  }}
                >
                  Pet Parent Details
                </Link>
              </Breadcrumbs>
            </div>
            <div className="petParentDetails ">
              <p align="center">
                <strong>Pet Parent Details</strong>
              </p>
              <div className="editParentDetails-form-container">
                <div className="editParentDetails-form-container-bottom">
                  <form
                    className="editParentDetails-form"
                    onSubmit={this.submitHandler}
                  >
                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <TextField
                          label="First Name"
                          variant="outlined"
                          type="text"
                          name="parent_firstname"
                          inputProps={{ maxLength: 30 }}
                          value={parent_firstname}
                          onChange={this.changeHandler}
                          required
                        />
                      </div>

                      <div className="editParentDetails-input-wrapper">
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          type="text"
                          name="parent_lastname"
                          inputProps={{ maxLength: 30 }}
                          value={parent_lastname}
                          onChange={this.changeHandler}
                          required
                        />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <TextField
                          label="Email"
                          variant="outlined"
                          type="email"
                          name="parent_email"
                          value={parent_email}
                          inputProps={{ maxLength: 254 }}
                          onChange={this.changeHandler}
                          required
                        />
                      </div>

                      <div className="editParentDetails-input-wrapper">
                        <MuiPhoneInput
                          label="Parent Contact"
                          defaultCountry="in"
                          value={`+${parent_phonecode} ${parent_phone}`}
                          regions={[
                            "america",
                            "caribbean",
                            "asia",
                            "middle-east",
                            "oceania",
                            "europe",
                            "africa",
                          ]}
                          name="parentNumber"
                          variant="outlined"
                          onChange={this.phoneNumberChangeHandler}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                edge="end"
                                aria-label="personal-details-icon"
                              >
                                <ContactPhoneIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <TextField
                          label="Alternate Email"
                          type="email"
                          variant="outlined"
                          inputProps={{ maxLength: 254 }}
                          name="parent_altemail"
                          value={parent_altemail}
                          onChange={this.changeHandler}
                        />
                      </div>
                      <div className="editParentDetails-input-wrapper">
                        <MuiPhoneInput
                          label="Alternate Contact"
                          defaultCountry="in"
                          value={`+${parent_altphonecode} ${parent_altphone}`}
                          regions={[
                            "america",
                            "caribbean",
                            "asia",
                            "middle-east",
                            "oceania",
                            "europe",
                            "africa",
                          ]}
                          variant="outlined"
                          onChange={this.alternatePhoneNumberChangeHandler}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                edge="end"
                                aria-label="personal-details-icon"
                              >
                                <ContactPhoneIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label1">
                            Gender *
                          </FormLabel>

                          <RadioGroup
                            aria-label="editParentDetails-radioGroup"
                            name="parent_gender"
                            value={parent_gender}
                            onChange={this.changeHandler}
                            required
                            row
                          >
                            <FormControlLabel
                              value="male"
                              control={<RadioButton />}
                              label="Male"
                            />

                            <FormControlLabel
                              value="female"
                              control={<RadioButton />}
                              label="Female"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      <div className="editParentDetails-input-wrapper">
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginTop: "-7px", marginLeft: "13px" }}
                          >
                            Country
                          </InputLabel>
                          <Select
                            variant="outlined"
                            label="Country"
                            name="parent_country"
                            value={
                              parent_country?.charAt(0).toUpperCase() +
                              parent_country?.slice(1)
                            }
                            onChange={this.changeHandler}
                          >
                            {Country?.getAllCountries()?.map((c) => {
                              return (
                                <MenuItem key={c.isoCode} value={c.name}>
                                  {c.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginTop: "-7px", marginLeft: "13px" }}
                          >
                            State
                          </InputLabel>
                          <Select
                            variant="outlined"
                            label="State"
                            name="parent_state"
                            value={
                              parent_state?.charAt(0).toUpperCase() +
                              parent_state?.slice(1)
                            }
                            onChange={this.changeHandler}
                          >
                            {State?.getStatesOfCountry(
                              countryWithIsoCode[0]?.isoCode
                            )?.map((s) => {
                              return (
                                <MenuItem key={s.isoCode} value={s.name}>
                                  {s.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="editParentDetails-input-wrapper">
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginTop: "-7px", marginLeft: "13px" }}
                          >
                            City
                          </InputLabel>
                          <Select
                            variant="outlined"
                            label="City"
                            name="parent_city"
                            value={
                              parent_city?.charAt(0).toUpperCase() +
                              parent_city?.slice(1)
                            }
                            onChange={this.changeHandler}
                          >
                            {City.getCitiesOfState(
                              countryWithIsoCode[0]?.isoCode,
                              getStateIsoCode[0]?.isoCode
                            )?.map((ct) => {
                              return (
                                <MenuItem key={ct.isoCode} value={ct.name}>
                                  {ct.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <TextField
                          label="Zip/Pin Code"
                          type="text"
                          variant="outlined"
                          inputProps={{ maxLength: 10 }}
                          // className="editParentDetails-input-tag"
                          name="parent_pincode"
                          value={parent_pincode}
                          onChange={this.changeHandler}
                        />
                      </div>

                      <div className="editParentDetails-input-wrapper">
                        <TextField
                          variant="outlined"
                          rows={4}
                          multiline
                          label="Parent Address"
                          placeholder="Enter parent address here"
                          name="parent_address"
                          value={parent_address}
                          onChange={this.changeHandler}
                          inputProps={{ maxLength: 500 }}
                        />
                      </div>
                    </div>

                    {/* <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <TextField
                          label="Address 2"
                          type="text"
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}
                          name="parent_address"
                          value={parent_address}
                          onChange={this.changeHandler}
                        />
                      </div>
                    </div> */}
                    {/*  */}
                    <div className="editParentDetails-btn-row">
                      <Button
                        variant="contained"
                        type="submit"
                        className="submit-btn-editPet"
                      >
                        {/* {this.state.redirecttomedicalpage ? "NEXT" : "SUBMIT"} */}
                        SUBMIT
                      </Button>

                      <Button
                        variant="contained"
                        className="cancel-btn-editPet"
                        type="button"
                        onClick={() => {
                          window.location.href =
                            "/#/pet-details/" +
                            this.props.match.params.tag_number;
                        }}
                      >
                        Back
                      </Button>
                    </div>

                    {this.state.showLoader && (
                      <div className="loader-container">
                        <div className="loader">
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                            }
                            alt="Loading..."
                            loading="lazy"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                    )}

                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={this.state.snackbaropen}
                      autoHideDuration={8000}
                      onClose={this.snackbarClose}
                      message={this.state.snackbarmsg}
                      action={[
                        <IconButton
                          arial-label="Close"
                          color="inherit"
                          onClick={this.snackbarClose}
                        >
                          X
                        </IconButton>,
                      ]}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderQRModal()}
      </>
    );
  }
}
