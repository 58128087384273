import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import Navbar from "./components/header/header.component.jsx";
import Footer from "../src/components/footer/footer.component.jsx";
import Faqs from "./components/faq/faq.component.jsx";
import Home from "./components/home/home.component.jsx";
import Legalterms from "./components/legalterms/legalterms.component.jsx";
import PetActivation from "../src/components/petactivation/petactivation.component.jsx";
import EditPetDetails from "../src/components/editpetdetails/editpetdetails.component.jsx";
import EdiParentDetails from "../src/components/editparentdetails/editparentdetails.component.jsx";
import Calendar from "./components/calendar/calendar.component.jsx";
import MedicalDashboard from "../src/components/medicaldashboard/medicaldashboard.component.jsx";
// import ReportFound from "./components/reportfound/reportfound.component.jsx";
import ScanRouting from "./components/scan-routing/scanrouting.component.jsx";
import ReportLost from "./components/reportlost/reportlost.component.jsx";
import PetPoster from "../src/components/petposter/petposter.component.jsx";
import EditReportLost from "../src/components/reportlost/edit-report-lost/editreportlost.component.jsx";
import ViewPetDetails from "../src/components/reportfound/viewpetdetails/viewpetdetails.component.jsx";
// import ViewMedicalDetails from "../src/components/reportfound/viewpetmedicaldetails/viewmedicaldetails.component.jsx";
// import PetToggle from "../src/components/reportfound/pettoggle/pettoggle.component.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import PhotoGallery from "../src/components/photogallery/photogallery.jsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  let usertoken = readCookie("token");
  const [isAuth, setIsAuth] = useState(usertoken !== null ? true : false);
  useEffect(() => {
    setIsAuth(usertoken !== null ? true : false);
  }, [isAuth]);

  return (
    <>
      <div className="PageWrapper">

  
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HashRouter>
        <div className="AppWrapper">
            {/* <Navbar /> */}

            <Switch>
              <Route
                path={"/photogallery/:tag_number"}
                render={(props) => <PhotoGallery {...props} />}
              />
              {/* <Route
              path={"/scans/:tag_number"}
              render={(props) => <PetToggle {...props} />}
            /> */}
              {/* <Route
              path={"/view-medical-details/:tag_number"}
              render={(props) => <ViewMedicalDetails {...props} />}
            /> */}
              <Route
                path={"/view-pet-details/:tag_number"}
                render={(props) => <ViewPetDetails {...props} />}
              />
              <Route
                path={"/scan/:tag_number"}
                render={(props) => <ScanRouting {...props} />}
              />

              <ProtectedRoute
                path={"/edit-report-lost/:tag_number"}
                component={EditReportLost}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/pet-poster/:tag_number"}
                component={PetPoster}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/reportlost/:tag_number"}
                component={ReportLost}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/medical-dashboard/:tag_number"}
                component={MedicalDashboard}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/calendar/:uid"}
                component={Calendar}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/pet-parent-details/:tag_number"}
                component={EdiParentDetails}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/pet-details/:tag_number"}
                component={EditPetDetails}
                isAuth={isAuth}
              />
              <ProtectedRoute
                path={"/pet-activation/:tag_number"}
                component={PetActivation}
                isAuth={isAuth}
              />
              <Route path={"/legal-terms-and-conditions"}>
                <Legalterms />
              </Route>
              <Route path={"/faq"}>
                <Faqs />
              </Route>
              <Route path={"/"}>
                <Home />
              </Route>
            </Switch>
          </div>
        </HashRouter>
      </LocalizationProvider>
        <div className="FooterWrapper"> <Footer /> </div>
        </div>
    </>
  );
}

export default App;
